/*===========================
 =  Table of Contents
 0. Keyframes
 1. General
 1.1. Typography
 1.2. Section spacings
 1.3. Forms
 1.4. Buttons
 1.5. Colors & Badges
 1.6. Modals, Popups & Dropdowns
 1.7. Tables
 1.8. Tabs
 1.9. Social media links
 2. Header
 2.1. Header style 2
 2.2. Header style 3
 3. Footer
 5. Subheader
 5. Components
 6.. Loaders
 6.1. Blog Posts
 6.1.1. Blog Posts List
 6.1.2. Blog Post Details
 6.2. sidebar
 6.3. Products
 6.3.1. Products (List view)
 6.3.2 Products (Minimal View)
 6.3.3 Products Quick view & details
 6.3.4. Cart & Wishlist
 6.3.5. Checkout
 6.3.6 Products (Recent View)
 6.4. Categories Megamenu
 6.5. Banners
 6.6. Icon Blocks
 6.7. Call To Actions
 6.8.Login & Signup
 6.9. Error 404
 6.10. Testimonials
 6.11. Companies
 6.12. Newsletter Popup
 7. Misc
 8. Responsive
===========================*/
/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');

/*-------------------------------------------------------------------*/
/* === Keyframes === */

@keyframes pulseInOut{
  0%{opacity:.0;transform:scale(.3)}
  50%{opacity:1;transform:scale(1.3)}
  100%{opacity:.0;transform:scale(.3)}
}

@keyframes BounceUpDown {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-2px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes BounceDownUp {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@keyframes bannerscale {
    50% {
        transform: scale(1.2);
    }
}

@keyframes live-icon {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

/*======================
1.0. General
========================*/
.custom-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.custom-img-1{
  position: absolute;
  bottom: -210px;
  right: 0;
  z-index: -3;
}

.spacer{
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}
.spacer.spacer-lg{
  height: 167px;
}
.spacer.spacer-xl{
  height: 300px;
}
.spacer.spacer-bottom{
  top: auto;
  bottom: 0;
}

.spacer.spacer-vertical{
  height: 100%;
  width:  140px;
}

.spacer.spacer-vertical.spacer-right{
  right: 0;
  left: auto;
}
.spacer.spacer-vertical.spacer-left{
  right: auto;
  left: 0;
}

.spacer.spacer-vertical.spacer-xl{
  width: 500px;
}

.sigma_map{
  height: 400px;
  width: 100%;
}
.sigma_map iframe{
  width: 100%;
  height: 100%;
}
.sigma_seperator-vertical{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sigma_seperator-vertical span{
  width: 1px;
  height: 200px;
  background-color: #eee;
  display: block;
}
.sigma_seperator-vertical span:first-child,
.sigma_seperator-vertical span:last-child{
  margin: 0 20px;
}
.sigma_seperator-vertical span:nth-child(2){
  height: 400px;
}

.crayon-texture-1,
.crayon-texture-2{
  position: relative;
}
.crayon-texture-1::after{
  content: '';
  position: absolute;
  width: 100%;
  bottom: -15px;
  z-index: 2;
  padding-top: 15px;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
  background-image: url(../img/texture-1.svg);
  transform: rotate(180deg);
}
.crayon-texture-2{
  background-image: url(../img/texture-2.svg);
  background-color: transparent;
}

.stroke-text{
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-weight: 400;
}

.sigma_preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: .3s;
}

.sigma_preloader.hidden {
    opacity: 0;
    visibility: hidden;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #ff9044;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .50;
}

.opacity-75 {
    opacity: .75;
}

.opacity-100 {
    opacity: 1;
}

.dark-overlay {
    position: relative;
    z-index: 1;
}

.dark-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}

.dark-overlay.dark-overlay-2::before {
    background-color: rgba(0, 0, 0, .6);
}

.sigma_sticky-section {
    position: sticky;
    top: 30px;
}

html {
    overflow-x: hidden;
}

body {
    font-family: 'Open Sans', monospace;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;
    color: #86858c;
    overflow-x: hidden;
}

hr {
    margin: 30px 0;
}

img {
    max-width: 100%;
    height: auto;
}

.imgs-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.imgs-wrapper img {
    position: absolute;
    max-width: 300px;
}

.imgs-wrapper img:nth-child(1) {
    top: 150px;
    max-width: 400px;
    left: -200px;
}

.imgs-wrapper img:nth-child(2) {
    right: -100px;
    bottom: 0;
}

.sigma_single-img-wrapper {
    position: relative;
}

.sigma_single-img-wrapper img {
    border-radius: 8px;
}

.sigma_dots {
    width: 100%;
    height: 100%;
    content: '';
    background-position: 0 0, 6px 6px;
    background-size: 12px 12px;
    position: absolute;
    display: block;
    background-image: -o-radial-gradient(#d1d4d8 1px, transparent 1px), -o-radial-gradient(#d1d4d8 1px, transparent 1px);
    background-image: radial-gradient(#d1d4d8 1px, transparent 1px), radial-gradient(#d1d4d8 1px, transparent 1px);
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    background-color: transparent;
    top: -17px;
    left: 17px;
    opacity: 1;
    z-index: -1;
}

.signature {
    width: 220px;
    margin-bottom: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.close-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
}

.close-btn:focus {
    outline: none;
}

.close-btn span {
    position: absolute;
    width: 2px;
    height: 15px;
    display: block;
    background-color: #fff;
    opacity: 0.6;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.close-btn.close-dark span {
    background-color: #1f1f1f;
}

.close-btn.close-danger span {
    background-color: red;
}

.close-btn span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.close-btn:hover span {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.close-btn:hover span:nth-child(2) {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.close-btn.dark span {
    background-color: #1f1f1f;
}

.icon-2x {
    font-size: 2rem;
}

.icon-3x {
    font-size: 3rem;
}

.icon-4x {
    font-size: 4rem;
}

.border-0 {
    border: 0;
}

.bg-cover {
    background-size: cover;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-contain {
    background-size: contain;
}

.bg-norepeat {
    background-repeat: no-repeat;
}

.bg-center {
    background-position: center;
}

.bg-left {
    background-position: left;
}

.bg-right {
    background-position: right;
}

.bg-top {
    background-position: top;
}

.bg-bottom {
    background-position: bottom;
}

.sigma_expaneded-row {
    flex-wrap: nowrap;
}

/*======================
1.1. Typography
========================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-family: 'Open Sans', serif;
  font-weight: 700;
  line-height: 1.2;
  color: #1f1f1f;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 34px;
  line-height: 1.2;
}

h4 {
  font-size: 26px;
  line-height: 1.4;
}

h5 {
  font-size: 20px;
  line-height: 1.5;
}

h6 {
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
}

p {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  color: #838383;
}

a {
  color: #ff9044;
  text-decoration: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #1f1f1f;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h5 a:focus,
h6 a:focus {
  color: #ff7c22;
}

a:hover,
a:focus {
    color: #ff7c22;
    text-decoration: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

b,
strong {
    font-weight: 700;
}

label {
    margin-bottom: 10px;
    font-weight: 600;
    color: #1f1f1f;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
}

ul li,
ol li {
    margin: 10px 0 0;
    position: relative;
}

.sigma_list li {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
}

.sigma_list li+li {
    margin-top: 10px;
}

.sigma_list li::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f055";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    font-size: 12px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    margin-right: 10px;
    color: #ff9044;
}

.small,
small {
  line-height: 1.7;
}

blockquote {
  position: relative;
  margin: 20px 0;
  font-size: 16px;
  color: #1f1f1f;
  padding: 0 0 0 50px;
  font-weight: 600;
}
blockquote::before{
  content: "\f10d";
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  left: 0;
  top: 0;
  color: #ff9044;
  font-size: 30px;
  opacity: .4;
}

blockquote p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1f1f1f;
}
blockquote cite{
  position: relative;
  padding-left: 15px;
  color: #ff9044;
}
blockquote cite::before{
  content: '';
  width: 10px;
  height: 2px;
  display: block;
  background-color: #ff9044;
  position: absolute;
  top: 50%;
  left: 0;
}

.entry-content p,
.entry-content img {
    margin-bottom: 20px;
}

.entry-content p:last-child {
    margin-bottom: 0;
}

.entry-content .sigma_post-share {
    margin-bottom: 20px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.border-25 {
    border-radius: 25px;
}

.cursive-style {
    font-family: 'La Belle Aurore', cursive;
}

.sigma_image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}

/*======================
1.2 Section Spacings
========================*/
.section {
    position: relative;
    padding: 110px 0;
}
.section .container{
  position: relative;
}

.section.section-padding {
    padding: 110px 0 80px;
}

.sigma_section-fw {
    padding: 0 80px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}

.mr-lg-30 {
    margin-left: 30px;
}

.section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
}

.section-title .subtitle {
    margin-bottom: 5px;
    font-size: 22px;
    color: #ff9044;
    font-weight: 600;
}

.section-title .subtitle.custom-primary {
    color: #1f1f1f;
}

.section-title .title {
    position: relative;
    margin: 0;
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 15px;
}

.section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 650px;
}
.section-title.text-center{
  margin: 0 auto 50px;
}

.section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.section-title.flex-title .nav {
    margin-left: auto;
}

.section-title.flex-title .nav-item {
    margin: 0;
}

.section-title.flex-title .nav-item+.nav-item {
    margin-left: 10px;
}

.section-title.flex-title .nav-item .nav-link {
    margin: 0;
}

.section-button{
  margin-top: 50px;
}

.ov-hidden {
    overflow: hidden;
}

/*==================
1.3. Forms
====================*/
textarea {
    resize: none;
}

.form-control::-webkit-input-placeholder {
    color: #a5a5a5;
}

.form-control::-moz-placeholder {
    color: #a5a5a5;
}

.form-control:-ms-input-placeholder {
    color: #a5a5a5;
}

.form-control::-ms-input-placeholder {
    color: #a5a5a5;
}

.form-control::placeholder {
    color: #a5a5a5;
}

.form-control {
    height: 50px;
    padding: 8px 15px;
    border-radius: 0;
    border: 1px solid #F0F2F3;
    border-radius: 0;
    width: 100%;
    color: #6e6e6e;
    background-color: #fff;
    letter-spacing: 0.1px;
    font-size: 14px;
}

.form-control:focus {
    border: 1px solid #efefef;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group {
    margin-bottom: 20px;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input,
.input-with-icon select {
    padding-left: 40px;
}

.input-with-icon i {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 18px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #a5a5a5;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.input-with-icon input:focus+i,
.input-with-icon select:focus+i {
    color: #454545;
}

.custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #ff9044;
}

.custom-control label {
    font-weight: 400;
}

/*==================
1.4. Buttons
====================*/
.btn-link {
  position: relative;
  color: #1f1f1f;
  font-weight: 600;
}
.btn-link i{
  margin-left: 5px;
  transition: .3s;
}
.btn-link::before{
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1f1f1f;
  z-index: 2;
  will-change: transform,color;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition: -webkit-transform .6s cubic-bezier(.694,0,.335,1);
  transition: transform .6s;
  transition: transform .6s,-webkit-transform .6s;
  transition-timing-function: cubic-bezier(.694,0,.335,1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.btn-link:hover::before{
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.btn-link:hover {
  color: #1f1f1f;
  text-decoration: none;
}
.btn-link:hover i{
  transform: translateX(5px);
}

.input-group-text {
  color: #fff;
  padding: 5px 20px;
  font-size: 22px;
  background-color: #ff9044;
  border: 1px solid #ff9044;
}

.sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #ff9044;
    border: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 0px;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .1);
}

.sigma_btn-custom:hover,
.sigma_btn-custom:focus {
    color: #fff;
    background-color: #ff7c22;
    outline: none;
}

.sigma_btn-custom.primary {
    background-color: #ff9044;
}

.sigma_btn-custom.primary:hover,
.sigma_btn-custom.primary:focus {
    background-color: #ff7c22;
}

.sigma_btn-custom.light {
    background-color: #ffebdd;
    color: #ff9044;
    box-shadow: none;
}

.sigma_btn-custom.light:hover,
.sigma_btn-custom.light:focus {
    background-color: #ff9044;
    color: #fff;
}

.sigma_btn-custom.secondary {
    background-color: #1f1f1f;
    color: #fff;
}

.sigma_btn-custom.secondary:hover,
.sigma_btn-custom.secondary:focus {
    background-color: #353535;
}

.sigma_btn-custom.btn-sm {
    padding: 12px 16px;
    font-size: 13px;
}


.btn-group .sigma_btn-custom+.sigma_btn-custom {
    padding: 12px 14px;
}

.btn-group .sigma_btn-custom.btn-sm+.sigma_btn-custom.btn-sm {
    padding: 12px 16px;
}

.input-group-append .sigma_btn-custom,
.input-group-append .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceUpDown;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.btn-group.dropup .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceDownUp;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.sigma_round-button{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #838383;
}
.sigma_round-button:hover{
  color: #838383;
}
.sigma_round-button svg{
  width: 40px;
  height: 40px;
}
.sigma_round-button i{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.sigma_round-button .sigma_round-button-stroke{
  fill: none;
  stroke: #1f1f1f;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #1f1f1f;
}
.sigma_round-button .sigma_round-button-circle{
  fill: none;
  stroke: #1f1f1f;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #1f1f1f;
}
.sigma_round-button:hover .sigma_round-button-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}

/*==================
1.5. Colors & Badges
====================*/
.custom-primary {
  color: #ff9044;
}
.custom-secondary {
  color: #1f1f1f;
}

.twitter {
    background-color: rgb(44, 170, 225) !important;
    color: #fff;
}

.twitter:hover {
    background-color: #2caae1;
    color: #fff;
}

.facebook {
    background-color: rgb(59, 87, 157) !important;
    color: #fff;
}

.facebook:hover {
    background-color: #3b579d;
    color: #fff;
}

.google {
    background-color: rgb(220, 74, 56) !important;
    color: #fff;
}

.google:hover {
    background-color: #dc4a38;
    color: #fff;
}

.linkedin {
    background-color: rgb(1, 119, 181) !important;
    color: #fff;
}

.linkedin:hover {
    background-color: #0177b5;
    color: #fff;
}

.pinterest {
    background-color: rgb(204, 33, 39) !important;
    color: #fff;
}

.pinterest:hover {
    background-color: #cc2127;
    color: #fff;
}

.youtube {
    background-color: rgb(229, 45, 39);
    color: #fff;
}

.youtube:hover {
    background-color: #e52d27;
    color: #fff;
}

.github {
    background-color: rgb(51, 51, 51) !important;
    color: #fff;
}

.github:hover {
    background-color: #333333 !important;
    color: #fff;
}

.behance {
    background-color: rgb(23, 105, 255) !important;
    color: #fff;
}

.behance:hover {
    background-color: #1769ff;
    color: #fff;
}

.dribbble {
    background-color: rgb(234, 76, 137) !important;
    color: #fff;
}

.dribbble:hover {
    background-color: #ea4c89;
    color: #fff;
}

.reddit {
    background-color: rgb(255, 69, 0) !important;
    color: #fff;
}

.reddit:hover {
    background-color: #ff4500;
    color: #fff;
}

.light-bg {
    background-color: #F0F2F3;
}

.primary-bg {
    background-color: #ff9044;
}

.secondary-bg {
    background-color: #1f1f1f;
}

.sigma_img-custom{
  position: relative;
  z-index: 1;
}
.sigma_img-custom img{
  width: 100%;
}

.sigma_img-custom::before{
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
  height: 100%;
  background-color: #1f1f1f;
  z-index: -1;
  transition: .3s;
}
.sigma_img-custom:hover::before{
  left: 0;
  top: 0;
}

.badge{
  background-color: #ffebdd;
  padding: 6px 11px;
  font-size: 20px;
  border-radius: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;
}
.badge.badge-primary{
  color: #ff9044;
  background-color: #ffebdd;
}

/*=============================
1.6 Modal, Popups & Dropdowns
==============================*/
.modal-header .close {
    background-color: #020202;
    opacity: 1;
    margin: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-shadow: none;
}

.modal-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-header .close:not(:disabled):not(.disabled):hover {
    background-color: #dd3333;
    opacity: 1;
}

.modal-lg {
    max-width: 1000px;
}

.modal-content {
    border-radius: 8px;
    border: 0;
}

.modal-body {
    padding: 40px;
}

.dropdown-menu {
    padding: 10px;
    font-size: 14px;
    color: #838383;
    border: 1px solid #F0F2F3;
    box-shadow: 0 4px 24px rgba(0, 0, 0, .1);
}

.dropdown-item {
    padding: 6px 10px;
    font-weight: 600;
    color: #838383;
    border-radius: 8px;
}

.dropdown-item i {
    display: inline-block;
    width: 25px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #f8f9fa;
    color: #16181b;
}

/*======================
1.7 Tables
========================*/
table{
  margin-bottom: 30px;
}
table th,
table td {
    padding: 15px;
    vertical-align: middle;
    background-color: #fff;
}

table th {
    font-weight: 700;
    color: #1f1f1f;
    font-family: "Open Sans", sans-serif;
    border: 1px solid #F0F2F3;
}

table {
    width: 100%;
    margin-bottom: 30px;
}

table td {
    border: 1px solid #F0F2F3;
}

table img {
    width: 40px;
    border-radius: 0;
}

.thead-dark tr th{
  background-color: #1f1f1f;
  color: #fff;
  border-color: #32383e;
}

/*======================
1.8 Tabs
========================*/
.tab-content {
    margin-top: 20px;
}

.nav-item {
    margin: 0;
}

.nav-link {
    padding: 10px 20px;
    background-color: #F0F2F3;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 0;
    color: #1f1f1f;
    border-radius: 0;
}

.nav-link.active {
    background-color: #ff9044;
    color: #fff;
}

/*======================
1.9. Social media links
========================*/
.sigma_sm {
    display: flex;
    align-items: center;
    margin: 0;
}

.sigma_sm li {
    margin: 0;
}

.sigma_sm li a {
    display: inline-block;
}

.sigma_sm li+li {
    margin-left: 20px;
}

/*======================
2. Header
========================*/

.sigma_header.header-fw .sigma_header-top > .container,
.sigma_header.header-fw .sigma_header-middle > .container,
.sigma_header.header-fw .sigma_header-bottom > .container{
  width: 100%;
  max-width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
}

.sigma_live i {
  font-size: 12px;
  margin-right: 5px;
  color: #fa170b;
  animation-name: live-icon;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes fadeHeaderInDown {
  0% {
    opacity:0;
    transform:translate3d(0, -100%, 0);
  }
  100% {
    opacity:1;
    transform:translate3d(0, 0, 0);
  }
}

.sigma_header.sticky.header-1 .sigma_header-middle,
.sigma_header.sticky.header-2 .sigma_header-middle,
.sigma_header.header-3.sticky .sigma_header-middle {
  position: fixed;
  top: -100%;
  width: 100%;
  z-index: 990;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.sigma_header.header-1.sticky .sigma_header-middle,
.sigma_header.header-3.sticky .sigma_header-middle,
.sigma_header.header-2.sticky .sigma_header-middle{
  top: 0;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  animation-name:fadeHeaderInDown;
}

.sigma_header.header-3 .sigma_header-middle:not(.is-alternate),
.sigma_header.header-2 .sigma_header-middle:not(.is-alternate),
.sigma_header.header-1 .sigma_header-bottom:not(.is-alternate) {
	animation-fill-mode: both;
	animation-duration: 1s;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.header-absolute .sigma_header-middle,
.header-absolute .sigma_header-bottom,
.header-absolute .sigma_header-top{
  background-color: transparent;
}

.sigma_header.header-1.sticky .sigma_header-middle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 190;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    background-color: #1f1f1f;
}
.sigma_header.header-1.sticky .aside-toggler span{
  background-color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler:hover span{
  background-color: #ff9044;
}
.sigma_header.header-1.sticky .sigma_header-controls-inner li a i,
.sigma_header.header-1.sticky.header-light .sigma_header-controls-inner li a i{
  color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler.desktop-toggler span,
.sigma_header.header-1.sticky.header-light .aside-toggler.desktop-toggler span{
  background-color: #fff;
}

.sigma_header-top {
  background-color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
  padding: 0;
}

.header-absolute .sigma_header-top{
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.sigma_header-top-inner {
    display: flex;
}

.sigma_header-top-links {
    display: flex;
    align-items: center;
    margin: 0;
}
.sigma_header-top-links + .sigma_header-top-links{
  margin-left: auto;
}

.sigma_header-top-links li {
    margin: 0;
}
.sigma_header-top-links li a i{
  margin-right: 10px;
}
.sigma_header-top-links > li + li > a{
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #1f1f1f;
}
.header-absolute .sigma_header-top-links > li + li > a{
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.sigma_header-top-sm li a,
.sigma_header-top-links a {
    color: #fff;
}

.sigma_header-top-sm li a:hover,
.sigma_header-top-links a:hover {
    color: #ff9044;
}

.sigma_header-top-links a {
    font-weight: 400;
    font-size: 13px;
    padding: 15px 0;
    line-height: normal;
    display: flex;
    align-items: center;
}

.sigma_header-top-links .sigma_account a {
    padding: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 10px 0;
}

.sigma_header-top-cta a{
  height: 100%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_header-top-user{
  margin-left: 30px;
  max-width: 40px;
}

.sigma_header-middle {
    background-color: #fff;
}

.sigma_header-middle .navbar {
    padding: 0;
}

.navbar-brand {
    padding: 10px 0;
    width: 150px;
}

/* Logo Card */
.sigma_logo-wrapper{
  position: relative;
}
.sigma_logo-card{
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ff9044;
  padding: 35px 25px;
  min-width: 500px;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  display: block;
  box-shadow: 0 2px 24px rgba(0,0,0,.04);
  z-index: 89;
  opacity: 0;
  visibility: hidden;
  transition: all .15s ease-out;
  transition-delay: .3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.sigma_logo-card-texture{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  z-index: -1;
}
.sigma_logo-card.style-2{
  margin-top: 20px;
}
.sigma_logo-card.style-2::before{
  top: -15px;
  right: auto;
  left: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #ff9044;
  border-top: 0;
}
.sigma_logo-card.style-2::after{
  display: none;
}
.sigma_logo-wrapper:hover .sigma_logo-card {
  opacity: 1;
  visibility: visible;
}
.sigma_logo-card p,
.sigma_logo-card strong,
.sigma_logo-card a{
  color: #fff;
}
.sigma_logo-card a:hover{
  opacity: .8;
}

.sigma_logo-card::before{
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 0;
  height: 0;
  border-top: 20px solid #fff;
  border-left: 20px solid transparent;
}
.sigma_logo-card::after{
  content: '';
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 0;
  height: 0;
  border-bottom: 20px solid #fff;
  border-right: 20px solid transparent;
}
.sigma_logo-card p{
  margin: 0;
}
.sigma_logo-card-logo{
  margin-bottom: 20px;
}

.sigma_logo-card .sigma_logo-card-item{
  display: flex;
  align-items: flex-start;
  margin: 0;
}
.sigma_logo-card .sigma_logo-card-item + .sigma_logo-card-item{
  margin-top: 20px;
}

.sigma_logo-card .sigma_logo-card-item i{
  margin-right: 10px;
  font-size: 20px;
  line-height: 1;
}
.sigma_logo-card .sigma_logo-card-item p{
  line-height: 1;
}
.sigma_logo-card .sigma_logo-card-item p + p{
  margin-top: 10px;
}
.sigma_logo-card .sigma_sm li + li{
  margin-left: 10px;
}
.sigma_logo-card .sigma_sm li a{
  background-color: rgba(255,255,255,.3);
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_logo-card .sigma_sm li a:hover{
  background-color: #fff;
  opacity: 1;
  color: #ff9044;
}

/* Controls */
.sigma_header-controls {
    display: flex;
    align-items: center;
}

.sigma_header-controls-inner {
    display: flex;
    align-items: center;
    margin: 0;
}

.sigma_header-controls-inner li {
    margin: 0;
}

.sigma_header-controls-inner li a {
    width: 58px;
    height: 58px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F2F3;
}

.sigma_header-controls-inner li a i {
    font-size: 21px;
    color: #1f1f1f;
}

.sigma_header-controls-inner li a:hover {
    background-color: #F0F2F3;
}

.sigma_header-controls-inner li:last-child a {
    margin-right: 0;
}

.sigma_header-controls-inner li.sigma_header-cart a {
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}

.sigma_header-controls-inner li.sigma_header-cart a i {
    font-size: 26px;
    color: #838383;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content {
    margin-left: 15px;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content span {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: #1f1f1f;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content span+span {
    color: #ff9044;
}

/* Controls Style 2 */
.sigma_header-controls.style-2 .aside-toggler.desktop-toggler,
.sigma_header-controls.style-2 .sigma_header-controls-inner li a{
  width: auto;
  height: auto;
  border-radius: 0;
  border: 0;
}
.sigma_header-controls.style-2 .aside-toggler span{
  margin-left: auto;
}

.sigma_header-controls.style-2 .sigma_header-controls-inner li a:hover{
  background-color: transparent;
}

/* Header Bottom */
.sigma_header-bottom {
    background-color: #1f1f1f;
}

.sigma_header-bottom-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_header-bottom-inner .navbar-nav,
.sigma_header-middle .navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.sigma_header-bottom-inner .navbar-nav .menu-item,
.sigma_header-middle .navbar-nav .menu-item {
    margin: 0;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item>a,
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a {
    position: relative;
    padding: 20px;
    color: #fff;
    display: block;
    font-weight: 600;
    font-size: 18px;
}

.sigma_header-middle .navbar>.navbar-nav>.menu-item>a {
    color: #1f1f1f;
    padding: 30px 20px;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item>a:hover,
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover {
    color: #ff9044;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item:first-child>a,
.sigma_header-middle .navbar>.navbar-nav>.menu-item:first-child>a {
    padding-left: 0;
}

.sigma_header-bottom-inner .navbar-nav .menu-item-has-children,
.sigma_header-middle .navbar-nav .menu-item-has-children {
    position: relative;
}

.sigma_header .sub-menu,
.sigma_flex-menu .sub-menu {
    position: absolute;
    top: 105%;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    min-width: 230px;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.sigma_flex-menu>ul {
    display: flex;
    margin: 0;
    align-items: center;
}

.sigma_flex-menu ul li {
    margin: 0;
}

.sigma_flex-menu>ul>li>a {
    padding: 0 10px 20px 10px;
    display: block;
    font-weight: 600;
    color: #1f1f1f;
}

.sigma_flex-menu>ul>li>a:hover {
    color: #ff9044;
}

.sigma_flex-menu>ul>li:first-child>a {
    padding-left: 0;
}

.sigma_header .sub-menu.sub-menu-left,
.sigma_flex-menu .sub-menu.sub-menu-left {
    left: auto;
    right: 0;
}

.sigma_header-middle .navbar-nav .menu-item-has-children:hover>.sub-menu,
.sigma_header-bottom-inner .navbar-nav .menu-item-has-children:hover>.sub-menu,
.sigma_search-adv-cats:hover .sub-menu,
.sigma_header-top-links .menu-item-has-children:hover .sub-menu,
.sigma_flex-menu .menu-item-has-children:hover .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.sigma_header .sub-menu a,
.sigma_flex-menu .sub-menu a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    color: #838383;
    font-weight: 400;
}

.sigma_header .sub-menu a:hover,
.sigma_flex-menu .sub-menu a:hover {
    background-color: #F0F2F3;
}

.sigma_header-middle .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
.sigma_header-bottom-inner .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
.sigma_search-adv-cats .sub-menu .sub-menu,
.sigma_header-top-links .menu-item-has-children .sub-menu .sub-menu,
.sigma_flex-menu .menu-item-has-children .sub-menu .sub-menu{
    top: 0;
    left: 100%;
}

.sigma_header .sub-menu .menu-item-has-children>a>span,
.sigma_flex-menu .sub-menu .menu-item-has-children>a>span {
    transform: rotate(-90deg);
}

.sigma_search-adv form {
    display: flex;
}

.sigma_search-adv-cats {
    position: relative;
    background-color: #F0F2F3;
    border-radius: 25px 0 0 25px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sigma_search-adv-cats label {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: #838383;
    font-weight: 400;
    border-bottom: 1px solid #F0F2F3;
    cursor: pointer;
    margin: 0;
    transition: .3s;
}

.sigma_header .sub-menu label:hover {
    background-color: #F0F2F3;
}

.sigma_search-adv-cats label input {
    position: absolute;
    opacity: 0;
    visibility: hidden
}

.sigma_search-adv-cats label input+i {
    color: #30ca71;
    transition: .3s;
    visibility: hidden;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    font-size: 12px;
}

.sigma_search-adv-cats label input:checked+i {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.sigma_search-adv-cats>span {
    color: #1f1f1f;
    font-size: 13px;
    font-weight: 600;
}

.sigma_search-adv-input .form-control {
    border-radius: 0 25px 25px 0;
}

.sidebar-widget .sigma_search-adv-input {
    width: 100%;
}

.sidebar-widget .custom-control-label {
    font-weight: 400;
}

.sidebar-widget .sigma_search-adv-input .form-control {
    border-radius: 0;
}

.sigma_search-adv-input {
    position: relative;
    width: 400px;
}

.sigma_search-adv-input button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #ff9044;
    border-radius: 0;
    color: #fff;
    transition: .3s;
    border-left: 1px solid #F0F2F3;
}

.sigma_search-adv-input button:hover {
    background-color: #ff7c22;
}

.sigma_search-adv-cats .sub-menu li,
.sigma_search-adv-cats .sub-menu {
    margin: 0;
}

.sigma_search-form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.8);
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.sigma_search-form-wrapper.open {
  opacity: 1;
  visibility: visible;
}
.sigma_search-form-wrapper .close-btn {
  position: absolute;
  top: 60px;
  right: 60px;
}
.sigma_search-form-wrapper .close-btn span{
  height: 40px;
}
.sigma_search-form-wrapper form {
  position: relative;
  width: 70%;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transform: scale(.5);
  -ms-transform: scale(.5);
  transform: scale(.5);
  opacity: 0;
  visibility: hidden;
}
.sigma_search-form-wrapper.open form {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.sigma_search-form-wrapper form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  padding: 20px 0;
  font-size: 40px;
  width: 100%;
  outline: none;
  color: #fff;
}
.sigma_search-form-wrapper form input::placeholder{
  color: #fff;
}
.sigma_search-form-wrapper .sigma_search-btn {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 30px;
  outline: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
  padding: 0;
}

/*header-light*/
.header-light .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
  color: #fff;
}

.header-light .sigma_header-controls-inner li a i{
  color: #fff;
}

.header-light .aside-toggler span,
.header-light .aside-toggler.desktop-toggler span{
  background-color: #fff;
}

.header-light .sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover,
.header-light .sigma_header-top-sm li:hover a{
  color: #ff9044;
}

/* Mega Menu */
.navbar-nav .menu-item.mega-menu-wrapper {
    position: static;
}
.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li > img{
  position: absolute;
  width: 160px;
  right: 20px;
  bottom: 0;
}

.navbar-nav .menu-item.mega-menu-wrapper .sub-menu {
    width: 100%;
}

.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li {
    margin: 0;
    padding: 20px;
}

.mega-menu-promotion-wrapper {
    margin-top: 30px;
}

.navbar-nav .mega-menu-promotion {
    text-align: center;
}

.navbar-nav .mega-menu-promotion>a {
    display: block;
    padding: 0;
}

.navbar-nav .mega-menu-promotion img {
    width: 150px;
    margin-bottom: 5px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.navbar-nav .mega-menu-promotion:hover img {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
}

.mega-menu-promotion-text h4 {
    margin-bottom: 5px;
    font-size: 18px;
}

.mega-menu-promotion-text span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #848486;
}

.navbar-nav .mega-menu-promotion-text h4 a {
    display: block;
    padding: 0;
    color: #1f1f1f;
}

.navbar-nav .mega-menu-promotion-text h4 a:hover {
    color: #ED4E53;
}

.navbar-nav .mega-menu-item h6 {
    margin-bottom: 10px;
    font-weight: 700;
}

.navbar-nav .mega-menu-item p {
    padding: 10px 0;
}

.navbar-nav .mega-menu-item a {
    display: block;
    font-weight: 400;
    padding: 10px 0;
}

.navbar-nav .mega-menu-item a:last-child {
    border-bottom: 0;
}

.navbar-nav .mega-menu-item a:hover {
    background-color: transparent;
    color: #ff9044;
}

.navbar-nav .mega-menu-item .sigma_btn-custom {
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    font-weight: 600;
}

.navbar-nav .mega-menu-item .sigma_btn-custom:hover {
    color: #fff;
    background-color: #ff7c22;
}

.navbar-nav .mega-menu-item a.coming-soon {
    color: #b7b7b7;
    cursor: default;
}

.navbar-nav .mega-menu-item a.coming-soon span {
    margin-left: 5px;
    font-size: 12px;
}

/*sigma_header-controls*/
.sigma_header-controls {
    display: flex;
    align-items: center;
}

.sigma_header-controls ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.sigma_header-controls ul>li {
    margin-left: 20px;
    margin-top: 0;
}

.sigma_header-controls ul>li>a {
  position: relative;
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.sigma_notification-count {
  background: #ff9044;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  line-height: normal;
  position: absolute;
  top: -10px;
  right: -10px;
}

/* Mobile sidenav */
.aside-toggler {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    transition: .3s;
}

.aside-toggler.desktop-toggler {
    display: flex;
    height: 58px;
    width: 58px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: .3s;
    border-radius: 8px;
    border: 1px solid #F0F2F3;
    margin-left: 20px;
}

.aside-toggler.desktop-toggler span {
    background-color: #1f1f1f;
}

.aside-toggler.desktop-toggler:hover {
    background-color: transparent;
}

.aside-toggler span {
    display: block;
    margin-bottom: 4px;
    width: 30px;
    height: 2px;
    border-radius: 3px;
    background-color: #1f1f1f;
    transition: .3s;
    transform-origin: right;
}

.aside-toggler span:nth-child(2) {
    width: 25px;
}

.aside-toggler span:nth-child(3) {
    margin-bottom: 0;
    width: 20px;
}

.sigma_header-controls.style-2 .aside-toggler.style-2,
.aside-toggler.style-2{
  width: 20px;
  height: 20px;
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 4px;
}
.sigma_header-controls.style-2 .aside-toggler.style-2.desktop-toggler,
.aside-toggler.style-2.desktop-toggler{
  display: grid;
}

.aside-toggler.style-2 span{
  width: 4px;
  height: 4px;
  margin: 0;
}
.aside-toggler.style-2 span:nth-child(2){
  grid-row: 1;
  grid-column: 3;
}
.aside-toggler.style-2 span:nth-child(3){
  grid-row: 2;
  grid-column: 2;
}
.aside-toggler.style-2 span:nth-child(4){
  grid-row: 3;
  grid-column: 1;
}
.aside-toggler.style-2 span:nth-child(5){
  grid-row: 3;
  grid-column: 3;
}
.aside-toggler.style-2:hover{
  transform: rotate(45deg);
}
.aside-toggler.style-2:hover span{
  width: 4px;
  height: 4px;
  background-color: #ff9044;
}

.sigma_aside {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  transition: .5s cubic-bezier(.77,0,.175,1);
  overflow: auto;
}

.sigma_aside-overlay.aside-trigger-left,
.sigma_aside.sigma_aside-left {
    display: none;
}

.sigma_aside.sigma_aside-right {
    left: auto;
    right: -400px;
    padding: 30px;
    width: 400px;
}

.sigma_aside.sigma_aside-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    z-index: -1;
    background-position: 0 130%;
    background-repeat: no-repeat;

}

.sigma_aside.sigma_aside-right .sidebar {
    display: none;
}

.sigma_aside.open {
    left: 0;
}

.sigma_aside.sigma_aside-right.open {
    left: auto;
    right: 0;
}

.sigma_aside.open+.sigma_aside-overlay {
    opacity: 1;
    visibility: visible;
}

.sigma_aside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: rgba(0, 0, 0, .7);
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}

.sigma_aside ul {
    margin: 0;
}

.sigma_aside ul .menu-item {
    margin: 0;
}

.sigma_aside ul .menu-item a {
    padding: 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #1f1f1f;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sigma_aside ul .menu-item a:hover,
.sigma_aside ul .menu-item a.active {
    color: #ff9044;
}

.navbar-nav .menu-item-has-children>a::after,
.sigma_aside ul .menu-item.menu-item-has-children>a::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f078";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
}
.navbar-nav .menu-item-has-children>a::after{
  margin-left: 10px;
}
.navbar-nav .sub-menu .menu-item-has-children>a::after{
  content: "\f054";
}

.sigma_aside ul .menu-item .sub-menu {
    display: none;
    background-color: #F0F2F3;
}

.sigma_aside .navbar-brand {
    padding: 10px 15px;
    display: block;
    width: 200px;
    margin: 0;
}

.sigma_aside.sigma_aside-right .sidebar-widget .widget-title {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
    font-size: 18px;
}

.sigma_aside.sigma_aside-right .sidebar-widget.widget-categories-icons+.sidebar-widget {
    margin-top: 0;
}
.sigma_aside.sigma_aside-right .sidebar-widget{
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
}
.sigma_aside.sigma_aside-right .sidebar-widget .widget-title::after {
    display: none;
}

/* -- Aside trigger Hover sequence -- */
.aside-toggler:hover span {
  width: 30px;
}

/* Header Cart */
.sigma_cart-sidebar-wrapper.sigma_aside{
  padding: 0;
  width: 60vw;
  max-width: 1200px;
  right: -1200px;
}
.sigma_cart-sidebar{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sigma_cart-sidebar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 40px;
  text-transform: uppercase;
}
.sigma_cart-sidebar-body {
  position: relative;
  padding: 0 40px;
  height: calc(100% - 245px);
  overflow-y: auto;
}

.sigma_cart-sidebar-header .close-btn span {
  height: 30px;
  opacity: 1;
}
.sigma_cart-sidebar-header h4{
  margin: 0;
}
.sigma_cart-sidebar-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background-color: #f9f9f9;
  padding: 40px;
  margin-top: auto;
}
.sigma_cart-sidebar-footer h4 {
  margin: 0;
  font-size: 20px;
}
.sigma_cart-sidebar-footer h4 span {
  font-size: 28px;
  margin-left: 10px;
}

.cart-open .sigma_cart-sidebar-wrapper.sigma_aside{
  right: 0;
}
.cart-open .sigma_aside-overlay.sigma_cart-sidebar-overlay{
  opacity: 1;
  visibility: visible;
}

.sigma_cart-sidebar-item{
  position: relative;
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px dashed #9F9E9E;
}
.sigma_cart-sidebar-item:last-child{
  border-bottom: 0;
}
.sigma_cart-sidebar-item-body{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 270px;
  display: -ms-flexbox;
  display: flex;
}
.sigma_cart-sidebar-item-body img{
  margin-right: 15px;
  width: 90px;
}
.sigma_cart-sidebar-item-body-inner{
  flex: 1;
}
.sigma_cart-sidebar-item-body-inner h5{
  font-weight: 600;
  margin-bottom: 10px;
}

/* 2.1. Header Style 2 */
.sigma_header.header-2 .sigma_header-top,
.sigma_header.header-3 .sigma_header-top{
  border-bottom: 0;
}


/* 2.2. Header Style 3 */
.sigma_header.header-3 .sigma_header-middle .navbar-nav{
  margin-right: auto;
  margin-left: 30px;
}
.sigma_header.header-3 .sigma_header-bottom{
  background-color: #fff;
  border-top: 1px solid #efefef;
}
.sigma_header.header-3 .sigma_header-bottom-inner>.navbar-nav>.menu-item>a{
  color: #1f1f1f;
  font-weight: 400;
}
.sigma_header.header-3 .sigma_header-bottom-cta{
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% + 40px);
  z-index: 4;
  display: flex;
  align-items: center;
  transition: .3s;
  margin-top: -10px;
}
.sigma_header.header-3 .sigma_header-bottom-cta:hover{
  height: calc(100% + 60px);
}
.sigma_header.header-3 .sigma_header-bottom-cta::before{
  content: '';
  position: absolute;
  background: 0 0;
  border: 4px solid;
  border-color: transparent #b15516 #b15516 transparent;
  display: block;
  width: 9px;
  height: 9px;
  left: -9px;
  top: 0;
  z-index: 30000;
}
.sigma_header.header-3 .sigma_header-bottom-cta::after{
  content: ' ';
  position: absolute;
  background: 0 0;
  border: 4px solid;
  border-color: transparent transparent #b15516 #b15516;
  display: block;
  width: 9px;
  height: 9px;
  right: -9px;
  top: 0;
  z-index: 30000;
}
.sigma_header.header-3 .sigma_header-bottom-cta a{
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.sigma_header.header-3 .sigma_header-bottom-cta a::after{
  content: '';
  width: 0;
  height: 0;
  border-left: 71px solid transparent;
  border-right: 71px solid transparent;
  border-top: 30px solid #ff9044;
  position: absolute;
  transition: .3s;
  top: 100%;
  left: 0;
}
.sigma_header.header-3 .sigma_header-bottom-cta a:hover::after{
  border-top-color: #ff7c22;
}
.sigma_header.header-3 .sigma_header-bottom .sigma_sm{
  padding-right: 170px;
}
.sigma_header.header-3 .sigma_header-bottom .sigma_sm li a{
  color: #adadad;
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #efefef;
}
.sigma_header.header-3 .sigma_header-bottom .sigma_sm li a:hover{
  background-color: #ff9044;
  color: #fff;
}

/*======================
3. Footer
========================*/
.sigma_footer {
    background-color: #F0F2F3;
    padding: 50px 0 0;
}

.sigma_footer p {
    margin-bottom: 10px;
    color: #515151;
}

.sigma_footer-top {
  margin-bottom: 50px;
}
.sigma_footer-top .container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sigma_footer-middle a,
.sigma_footer-middle i {
    color: #515151;
}

.sigma_footer-middle a:hover {
    color: #ff9044;
}

.sigma_footer-middle a.sigma_btn-custom,
.sigma_footer-middle a.sigma_btn-custom:hover {
    color: #fff;
}

.sigma_footer-bottom ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 20px;
}

.sigma_footer-bottom ul li {
    margin: 0;
}

.sigma_footer-bottom ul li a {
    padding-right: 20px;
    color: #515151;
}

.sigma_footer-bottom ul li a:hover {
    color: #ff9044;
}

.sigma_footer-bottom .sigma_footer-copyright {
    padding-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.sigma_footer-bottom .sigma_footer-copyright p {
    margin: 0;
}

.sigma_footer-bottom .sigma_footer-copyright>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 600;
}

.sigma_footer-bottom .sigma_footer-copyright>a:hover {
    color: #ff9044;
}
.sigma_footer-bottom .sigma_footer-copyright>a.sigma_back-to-top .sigma_round-button{
  margin-left: 10px;
}
.sigma_footer-bottom .sigma_footer-copyright>a.sigma_back-to-top .sigma_round-button svg{
  width: 30px;
  height: 30px;
}
.sigma_footer-bottom .sigma_footer-copyright>a.sigma_back-to-top .sigma_round-button i{
  font-size: 12px;
}
.sigma_footer-bottom .sigma_footer-copyright>a.sigma_back-to-top:hover{
  color: #1f1f1f;
}
.sigma_footer-bottom .sigma_footer-copyright>a.sigma_back-to-top:hover .sigma_round-button-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}

.footer-widget {
    padding-bottom: 50px;
}
.sigma_footer-buttons{
  display: flex;
  align-items: center;
}
.sigma_footer-buttons a {
  display: inline-flex;
  align-items: center;
}
.sigma_footer-top.secondary-bg .sigma_footer-buttons a.sigma_btn-custom.light {
    background-color: #404040;
    color: #fff;
}
.sigma_footer-top.secondary-bg .sigma_footer-buttons a.sigma_btn-custom.light:hover {
  background-color: #1f1f1f;
}
.sigma_footer-top.secondary-bg .sigma_footer-buttons a.sigma_btn-custom.light .sigma_svg-icon {
    fill: #fff;
}
.sigma_footer-buttons a + a{
  margin-left: 15px;
}
.sigma_footer-buttons a .sigma_svg-icon {
  fill: #18181d;
  margin-right: 10px;
  display: block;
  width: 20px;
  height: auto;
  flex-shrink: 0;
}

.footer-widget .widget-title {
    margin-bottom: 20px;
    font-weight: 700;
}

.sigma_footer-logo {
    width: 180px;
}

.footer-widget ul {
    margin: 0;
}

.footer-widget ul li+li {
    margin: 10px 0 0;
}

.footer-widget ul li a {
    position: relative;
    display: block;
    -webkit-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
}

.footer-widget ul li a::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    color: #ff9044;
    padding-right: 10px;
}

.footer-widget ul li a i {
    font-weight: 400;
}

.footer-widget ul li a:hover {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.footer-widget ul li a:hover::before {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.footer-widget ul.social-media li {
    display: inline-block;
    margin-top: 0;
}

.footer-widget ul.social-media li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    color: #fff;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer-widget ul.social-media li a::before {
    display: none;
}

.footer-widget ul.social-media li a i {
    color: #fff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.footer-widget ul.social-media li a:hover i {
    color: #fff;
}

.sigma_footer-offer {
    margin-top: 15px;
}

.sigma_footer-offer p {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
}

.sigma_footer-offer .btn-custom,
.sigma_footer-offer .btn-custom:hover {
    color: #fff;
}

.sigma_instagram {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}

.sigma_ig-item {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}
.sigma_ig-item::before{
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 3;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  color: #ff9044;
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.sigma_ig-item:hover::before{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_ig-item:hover img {
  transform: scale(1.1);
  filter: blur(1px);
}

.sigma_ig-item img {
  transition: .3s;
}

/* Footer 2 */
.sigma_footer.footer-2{
  padding: 0;
  background-color: #fff;
}
.sigma_footer.footer-2 .sigma_footer-top{
  margin-bottom: 0;
}
.sigma_footer.footer-2 .sigma_footer-top-item{
  padding: 25px 15px;
  flex: 1;
  display: flex;
  align-items: center;
}
.sigma_footer.footer-2 .sigma_footer-top-item + .sigma_footer-top-item{
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.sigma_footer.footer-2 .sigma_footer-top.secondary-bg .sigma_sm li a{
  color: #fff;
  opacity: .8;
  font-size: 15px;
  display: block;
}
.sigma_footer.footer-2 .sigma_footer-sm{
  justify-content: flex-end;
}
.sigma_footer.footer-2 .sigma_footer-top.secondary-bg .sigma_sm li a:hover{
  color: #ff9044;
  opacity: 1;
}


.sigma_footer.footer-2 .sigma_footer-newsletter form{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form::before{
  content: "\f0e0";
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  color: rgba(255,255,255,.8);
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form input{
  background: no-repeat;
  border: 0;
  color: #fff;
  padding: 9px 25px 9px 10px;
  flex: 1;
  outline: none;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button:focus,
.sigma_footer.footer-2 .sigma_footer-newsletter form input:focus{
  outline: none;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form input::placeholder{
  color: rgba(255,255,255,.8);
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button{
  background: none;
  padding: 0;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 14px;
  float: right;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button i{
  margin-left: 10px;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button::before{
  background-color: #fff;
}
.sigma_footer.footer-2 .sigma_footer-middle{
  padding-top: 50px;
}

/* Dark Footer */
.sigma_footer.sigma_footer-dark {
  background-color: #1f1f1f;
}

.sigma_footer.sigma_footer-dark p,
.sigma_footer.sigma_footer-dark .footer-widget ul li a::before,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom ul li a:hover,
.sigma_footer.sigma_footer-dark .footer-widget ul li a:hover,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom .sigma_footer-copyright>a,
.sigma_footer.sigma_footer-dark .footer-widget .widget-title {
    color: #fff;
}

.sigma_footer.sigma_footer-dark .sigma_footer-bottom ul li a,
.sigma_footer.sigma_footer-dark .footer-widget ul li a {
    color: #b7b7b7;
}

.sigma_footer.sigma_footer-dark .sigma_footer-bottom ul {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

/*======================
5. Subheader
========================*/

.sigma_subheader {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sigma_subheader-inner {
    padding: 60px 0;
}
.header-absolute + .sigma_subheader .sigma_subheader-inner{
  padding: 230px 0 160px;
}

.sigma_subheader h1 {
    margin: 0;
    font-size: 60px;
    margin-bottom: 20px;
    color: #fff;
}

.sigma_subheader .breadcrumb {
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.sigma_subheader .breadcrumb li {
    margin: 0;
    color: #f1f1f1;
}
.sigma_subheader .breadcrumb-item+.breadcrumb-item{
  padding-left: 15px;
}

.sigma_subheader .breadcrumb li a {
  position: relative;
    color: #f1f1f1;
    font-weight: 700;
    text-transform: uppercase;
}
.sigma_subheader .breadcrumb li a::before{
  background-color: #fff;
}

.sigma_subheader .breadcrumb li a:hover {
    color: #fff;
}

.sigma_subheader .breadcrumb .breadcrumb-item.active {
    color: #fff;
    text-transform: uppercase;
}

.sigma_subheader .breadcrumb-item+.breadcrumb-item::before {
    color: #f1f1f1;
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    padding-right: 15px;
}

/*======================
6. Components
========================*/

/* 6.1. Blog Posts */
.sigma_post {
    position: relative;
    margin-bottom: 30px;
}

.sigma_post .sigma_post-thumb a,
.gallery-thumb {
    position: relative;
    display: block;
    overflow: hidden;
}
.sigma_post .sigma_post-thumb a img{
  width: 100%;
}

.sigma_post .sigma_post-thumb a::before,
.gallery-thumb::before {
    content: '';
    position: absolute;
    left: 0;
    top: -50%;
    width: 10%;
    height: 190%;
    background-color: rgba(255, 255, 255, .2);
    transition: .3s ease-in;
    opacity: 0;
    visibility: hidden;
    transform: rotate(45deg);
}

.sigma_post .sigma_post-thumb a:hover::before,
.gallery-thumb:hover::before {
    opacity: 1;
    visibility: visible;
    left: 190%;
}

.sigma_post .sigma_post-categories {
    margin: 0 0 17px;
}

.sigma_post .sigma_post-categories a {
    background-color: #ffebdd;
    display: inline-block;
    padding: 5px 8px;
    color: #ff9044;
    font-size: 13px;
    line-height: 1.7;
    border-radius: 0;
    font-weight: 600;
    margin: 0 3px 3px 0;
}

.sigma_post .sigma_post-categories a:hover {
    background-color: #ff9044;
    color: #fff;
}

.sigma_post .sigma_post-body {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 3px 24px rgba(0,0,0,.04);
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: -20px;
    position: relative;
    z-index: 1;
}

.sigma_post .sigma_post-body h5 {
    margin: 0 0 15px;
    word-break: break-word;
    font-weight: 700;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sigma_post .sigma_post-body h5 a {
    color: #1f1f1f;
}

.sigma_post .sigma_post-body h5 a:hover,
.sigma_post .sigma_post-body .sigma_post-desc>span a:hover {
    color: #ff7c22;
}

.sigma_post .sigma_post-body .sigma_post-desc>span {
    display: block;
    color: #1f1f1f;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 10px;
}

.sigma_post .sigma_post-body .sigma_post-desc>span a {
    color: #ff9044;
}

.sigma_post .sigma_post-body .sigma_post-desc p {
    margin-bottom: 20px;
}

/* 6.1.1. Blog Posts list */
.sigma_post.sigma_post-list {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #F0F2F3;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.sigma_post.sigma_post-list .sigma_post-body {
    border: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    padding: 0 20px;
    margin: 0;
    width: 100%;
}

.sigma_post.sigma_post-list .sigma_post-body,
.sigma_post.sigma_post-list .sigma_post-thumb {
    flex: 1;
}

.sigma_related-slider .slick-list{
  padding: 10px 0;
}

/* 6.1.2. Blog Post Details */
.comment-form {
    margin-bottom: 50px;
}

.comment-form, .comments-list{
  border: #f2f2f2 solid 1px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
  padding: 35px;
}

.comments-list ul {
    margin: 0;
}

.comments-list .comment-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.comments-list .comment-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.comments-list .comment-item img {
    width: 65px;
    margin-right: 20px;
    border-radius: 50%;
}

.comments-list .comment-item .comment-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.comments-list .comment-item h5 {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 16px;
}

.comments-list .comment-item span {
    display: inline-block;
    margin-bottom: 15px;
    color: #848486;
    font-size: 13px;
    line-height: 21px;
    position: absolute;
    top: 4px;
    right: 0;
}

.comments-list .comment-item p {
    margin-bottom: 15px;
}

.reply-link {
    position: relative;
    color: #1f1f1f;
    font-weight: 600;
}

.sigma_post-single .sigma_post-share {
    margin-top: 50px;
}

.sigma_post-single-thumb {
  position: relative;
    margin-bottom: 30px;
}
.sigma_post-single-thumb img{
  margin: 0;
}

.sigma_post-single-author {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.sigma_post-single-author img {
    width: 45px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 0;
}

.sigma_post-single-author-content p {
    font-weight: 600;
    color: #1f1f1f;
    margin-bottom: 0;
}

.sigma_post-single-author-content span {
    font-size: 13px;
}

.sigma_post-single-meta {
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-top: 50px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sigma_post-single-meta-item {
    flex: 1;
}
.sigma_post-single-meta-item h6{
  font-weight: 700;
  margin-bottom: 5px;
}

.sigma_post-share .sigma_sm li+li {
    margin-left: 10px;
}

.sigma_post-share .sigma_sm li a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_post-share .sigma_sm li a i {
    color: #838383;
}
.sigma_post-share h6{
  font-weight: 700;
  margin-bottom: 5px;
}

.sigma_single-pagination {
    margin: 30px 0;
}

.sigma_single-pagination-item+.sigma_single-pagination-item {
    margin-top: 10px;
}

.sigma_single-pagination-thumb {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F2F3;
    background-color: #fff;
    border-radius: 0;
    margin-right: 10px;
}

.sigma_single-pagination a {
    display: flex;
    align-items: center;
}

.sigma_single-pagination a h6 {
    margin: 0;
    font-size: 14px;
}

.sigma_single-pagination a img {
    max-width: 40px;
}

.sigma_single-pagination-prev,
.sigma_single-pagination-next {
    padding: 10px;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #F0F2F3;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    transition: .3s;
}

.sigma_single-pagination-prev:hover,
.sigma_single-pagination-next:hover {
    background-color: #F0F2F3;
}

.sigma_single-pagination i {
    color: #838383;
    margin-left: auto;
    margin-right: 20px;
    transition: .3s;
}

.sigma_single-pagination-prev:hover i {
    transform: translateX(-3px);
}

.sigma_single-pagination-next:hover i {
    transform: translateX(3px);
}

/* 6.2. Sidebar */
.sidebar-widget {
  padding: 25px 28px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}
.sidebar-widget + .sidebar-widget {
    margin-top: 30px;
}

.sidebar-widget .widget-title {
  position: relative;
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 18px;
}

.sidebar-widget .widget-title::after {
  position: absolute;
  left: -28px;
  top: 0;
  height: 100%;
  content: "";
  background-color: #ff9044;
  width: 5px;
}

.sidebar-widget ul.sidebar-widget-list {
    margin: 0;
    padding: 0;
}

.sidebar-widget ul.sidebar-widget-list ul {
    padding-left: 20px;
}

.sidebar-widget ul.sidebar-widget-list li {
    margin: 0;
}

.sidebar-widget ul.sidebar-widget-list li ul li:first-child {
    margin-top: 20px;
}

.sidebar-widget ul.sidebar-widget-list li+li {
    margin-top: 20px;
}

.sidebar-widget ul.sidebar-widget-list li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #838383;
}

.sidebar-widget ul.sidebar-widget-list li a:hover {
    color: #ff9044;
}

.widget-about-author-inner {
  text-align: center;
}

.widget-about-author-inner img {
    border-radius: 50%;
    width: 100px;
    margin: 0 auto 20px;
}

.widget-about-author-inner h5 {
    margin-bottom: 10px;
    font-weight: 700;
}

.widget-about-author .sigma_sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 8px 8px;
}

.widget-about-author .sigma_sm li+li {
    margin: 0;
}

.widget-about-author .sigma_sm li {
    flex: 1;
}

.widget-about-author .sigma_sm li a{
  display: flex;
  color: #707070;
}


.widget-recent-posts .sigma_recent-post{
  display: flex;
  align-items: center;
}

.widget-recent-posts .sigma_recent-post>a {
    position: relative;
    display: block;
    overflow: hidden;
    margin-right: 10px;
    width: 100px;
}

.widget-recent-posts .sigma_recent-post>a img {
    transition: .3s;
}

.widget-recent-posts .sigma_recent-post>a img:hover {
    transform: scale(1.1) rotate(2deg);
}

.widget-recent-posts .sigma_recent-post-body{
  flex: 1;
}
.widget-recent-posts .sigma_recent-post-body > a{
  color: #707070;
  font-size: 14px;
}
.widget-recent-posts .sigma_recent-post-body > a:hover{
  color: #ff7c22;
}

.widget-recent-posts .sigma_recent-post h6 {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 15px;
}

.widget-recent-posts .sigma_recent-post h6 a {
    color: #1f1f1f;
}

.widget-recent-posts .sigma_recent-post h6 a:hover,
.widget-recent-posts .sigma_recent-post span a:hover {
    color: #ff9044;
}

.widget-recent-posts .sigma_recent-post span {
    font-size: 13px;
    line-height: 1.7;
    font-weight: 600;
    color: #1f1f1f;
}

.widget-recent-posts .sigma_recent-post span a {
    font-weight: 400;
    text-decoration: underline;
    color: #1f1f1f;
}

.widget-recent-posts .sigma_recent-post+.sigma_recent-post {
    margin-top: 30px;
}

.tags a,
.tagcloud a,
.entry-content .wp-block-tag-cloud a {
    display: inline-block;
    font-size: 13px;
    padding: 3px 12px;
    color: #848486;
    border: 1px solid #eee;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background-color: #fff;
    margin: 6px 6px 0 0;
    border-radius: 0;
}

.tags a:hover,
.tagcloud a:hover,
.entry-content .wp-block-tag-cloud a:hover {
    background-color: #f5f5f5;
    border-color: rgba(0, 0, 0, 0.1);
    color: #848486;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.sigma_newsletter-form {
    position: relative;
}

.sigma_newsletter-form.primary-bg {
    background-color: #ff9044;
}

.sigma_newsletter-form h5 {
    margin-bottom: 15px;
    position: relative;
    font-weight: 700;
    font-size: 18px;
}

.deals-slider .slick-dots {
    justify-content: center;
    margin: 10px 0 0;
}

.deals-slider .sigma_product:hover .sigma_countdown-timer {
    background-color: #F0F2F3;
}

.sigma_countdown-timer {
    text-align: center;
    background-color: #fff;
    border: 1px solid #F0F2F3;
    padding: 10px;
    font-weight: 600;
    margin: 20px 0 0;
    transition: .3s;
}

/* 6.3. Products */
.sigma_product {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #F0F2F3;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    margin-bottom: 30px;
}

.sigma_product .sigma_product-thumb {
    margin: 0 0 20px;
    overflow: hidden;
}

.sigma_product .sigma_product-thumb.thumb-sm {
    max-width: 200px;
    margin: 0 auto 20px;
}

.sigma_product .sigma_product-thumb img {
    transition: .3s;
}

.sigma_product .sigma_product-thumb:hover img {
    transform: scale(1.1) rotate(3deg);
}

.sigma_product .sigma_product-body h5 {
    margin: 0 0 15px;
    word-break: break-word;
}

.sigma_product .sigma_product-body .sigma_product-title a {
    color: #1f1f1f;
}

.sigma_product .sigma_product-body .sigma_product-title a:hover {
    color: #ff7c22;
}

.sigma_product .sigma_badge-featured {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  background-color: #ffa80a;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.7;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_badge-featured i {
    display: inline-block;
}

.sigma_product .sigma_badge-sale {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  background-color: #1f1f1f;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.7;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_product .sigma_rating-wrapper span {
    font-weight: 600;
}

.sigma_product .sigma_product-footer {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #F0F2F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_product-price span {
    display: block;
}

.sigma_product-price span:first-child {
    font-size: 18px;
    line-height: 1.2;
    font-family: "Open Sans", sans-serif;
    color: #1f1f1f;
    font-weight: 700;
}

.sigma_product-price span+span {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #ff9044;
    font-weight: 700;
    text-decoration: line-through;
}

.sigma_product.sigma_product-has-controls .sigma_product-body .sigma_product-price {
    margin-bottom: 10px;
}

.sigma_product.sigma_product-has-controls .sigma_product-body .sigma_product-price span {
    display: inline-block;
}

.sigma_product.sigma_product-has-controls .sigma_product-body .sigma_product-price span:first-child {
    font-size: 15px;
}

.sigma_product.sigma_product-has-controls .sigma_product-body .sigma_product-price span+span {
    margin-left: 5px;
}

.sigma_product.sigma_product-has-buttons .sigma_product-footer {
    border-top: 0;
    padding: 0;
    margin: 20px -20px -20px -20px;
}

.sigma_product.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.sigma_product.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons a {
    flex: 1;
    box-shadow: none;
}

.sigma_product.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons a {
    border-radius: 0;
}

.sigma_product-stock {
    font-weight: 600;
}

.sigma_product-stock.instock {
    color: #58da58;
}

.sigma_product-stock.outofstock {
    color: #f54978;
}

.sigma_product .sigma_rating {
    margin-bottom: 0;
}

.sigma_product-controls {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.sigma_product-controls a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #F0F2F3;
}

.sigma_product-controls a i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
}

.sigma_product-controls a:hover {
    background-color: #ff9044;
    color: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

/* 6.3.1 Products (List View) */
.sigma_product.sigma_product-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.sigma_product.sigma_product-list .sigma_product-thumb {
    width: 125px;
    margin-right: 15px;
}

.sigma_product.sigma_product-list .sigma_product-body {
    flex: 1;
}

.sigma_product.sigma_product-list .sigma_product-footer {
    padding-top: 0;
    border-top: 0;
}

.sigma_product.sigma_product-list .sigma_rating-wrapper {
    margin-bottom: 10px;
}

.sigma_product.sigma_product-list .sigma_product-controls {
    justify-content: space-between;
}

.sigma_product.sigma_product-list.sigma_product-has-buttons .sigma_product-footer {
    width: calc(100% + 40px);
}

.sigma_product.sigma_product-list.sigma_product-has-buttons .sigma_product-footer {
    margin: 10px -20px -20px -20px;
}

/* 6.3.2 Products (Minimal View) */
.sigma_product.sigma_product-minimal {
    text-align: center;
}

.sigma_product.sigma_product-minimal .sigma_product-title {
    margin-bottom: 10px;
    font-size: 15px;
}

.sigma_product.sigma_product-minimal .sigma_product-footer {
    border: 0;
    padding-top: 0;
    margin-top: 5px;
    display: block;
    text-align: center;
}

.sigma_product.sigma_product-minimal .sigma_product-price {
    margin: 15px 0;
}

.sigma_product.sigma_product-minimal .sigma_product-price span {
    font-size: 14px;
    display: inline-block;
    line-height: 1.7;
}

.sigma_product.sigma_product-minimal .sigma_product-price span+span {
    margin-left: 5px;
    font-size: 13px;
}

.sigma_product.sigma_product-minimal .sigma_rating-wrapper {
    justify-content: center;
}

.sigma_product.sigma_product-minimal.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons {
    margin-top: 20px;
}

.sigma_shop-global {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #F0F2F3;
    background-color: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.sigma_shop-global p {
    margin-bottom: 0;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child {
    background-color: #1f1f1f;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #1f1f1f;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background-color: #1f1f1f;
}

/* 6.3.3 Products Quick View & Details */
.sigma_quick-view-modal .sigma_product-single-content {
    padding: 0 40px;
}

.sigma_quick-view-modal .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
}

.sigma_product-single-content .sigma_rating-wrapper {
    margin-bottom: 0;
}

.sigma_product-single-content .sigma_product-excerpt,
.sigma_product-single-content .sigma_product-variation-wrapper {
    margin-bottom: 25px;
}

.sigma_product-single-content .sigma_product-price {
    margin-bottom: 20px;
}

.sigma_product-single-content .sigma_product-price span:first-child {
    font-size: 22px;
}

.sigma_product-single-content .sigma_product-price span {
    display: inline-block;
}

.sigma_product-single-content .sigma_product-price span+span {
    margin-left: 10px;
}

.sigma_product-atc-form .qty-outter {
    display: flex;
    align-items: center;
}

.sigma_product-atc-form .qty-outter .sigma_btn-custom+.qty {
    margin-left: 20px;
}

.qty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.qty input {
    background-color: #fff;
    border: 0;
    outline: none;
    width: 50px;
    height: 50px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #F0F2F3;
}

.qty span.qty-subtract {
    border-right: 0;
}

.qty span.qty-add {
    border-left: 0;
}

.qty span:hover {
    background-color: #F0F2F3;
}

.qty span {
    cursor: pointer;
    width: 40px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #F0F2F3;
    font-size: 13px;
    background-color: #fff;
    transition: .3s;
}

.sigma_product-additional-info .nav {
    flex-direction: column;
}

.sigma_product-additional-info .nav-link {
    margin: 0;
    border-radius: 0;
}

.sigma_product-additional-info .nav-item+.nav-item .nav-link {
    margin-top: 10px;
}

.sigma_product-additional-info .tab-content {
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    z-index: 1;
    margin-top: 0;
}


.sigma_product-additional-info .tab-content table {
    margin: 0;
}

.sigma_product-additional-info .sigma_rating-wrapper,
.sigma_product-additional-info .comment-body .sigma_rating {
    margin-bottom: 20px;
}
.sigma_product-additional-info .tab-content .comment-form,
.sigma_product-additional-info .tab-content .comments-list{
  padding: 0;
  border: 0;
  box-shadow: none;
}
.sigma_product-additional-info .comment-form {
    margin-bottom: 50px;
}

.sigma_product-single-controls {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_product-single-controls .sigma_add-to-favorite {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F2F3;
    margin: 0 10px 0 0;
}

.sigma_product-single-controls .sigma_add-to-favorite:hover {
    background-color: #F0F2F3;
}

.sigma_product-single-controls .sigma_add-to-favorite i {
    font-size: 21px;
    color: #838383;
}

.sigma_product-meta {
    margin: 20px 0 0;
}

.sigma_product-single-2 .sigma_product-meta {
    margin: 20px 0 30px;
}

.sigma_product-meta li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.sigma_product-meta li>span {
    font-weight: 600;
    color: #1f1f1f;
    width: 120px;
}

.sigma_product-meta li .sigma_product-meta-item span,
.sigma_product-meta li a {
    color: #838383;
}

.sigma_product-meta li a:hover {
    color: #ff9044;
}

.sigma_product-single-thumb,
.sigma_product-single-zoom>img {
    background-color: #fff;
}

/* 6.3.4. Cart & Wishlist */
td.remove {
    width: 30px;
}

.sigma_cart-product-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_cart-product-wrapper img {
    width: 50px;
    margin-right: 15px;
}

.sigma_cart-product-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.sigma_cart-product-wrapper h6 {
    margin-bottom: 0;
    font-size: 14px;
}

.sigma_cart-product-wrapper h6 a {
    color: #1f1f1f;
}

.sigma_cart-product-wrapper h6 a:hover {
    color: #ff9044;
}

.sigma_cart-product-wrapper p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 21px;
    color: #848486;
}

/* 6.3.5. Checkout */
.sigma_notice {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #f2f2f2;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
}

.sigma_notice p {
    margin: 0;
    font-weight: 600;
}

.sigma_notice p a:hover {
    text-decoration: underline;
}

.sigma_notice-content {
    margin-bottom: 30px;
    display: none;
}

/* 6.3.6 Products (Recent View) */
.sigma_product-recent {
    position: relative;
    background-color: #fff;
    border: 1px solid #F0F2F3;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    border-radius: 8px;
    transition: .3s;
}

.sigma_product-recent:hover {
    background-color: #F0F2F3;
}

.sigma_product-recent .sigma_product-recent-thumb {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.sigma_product-recent+.sigma_product-recent {
    margin-top: 10px;
}

.sigma_product-recent img {
    width: 75px;
    margin-right: 10px;
}

.sigma_product-recent-content .sigma_product-price span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
}

.sigma_product-recent-content .sigma_product-price span+span {
    margin-left: 5px;
}

.sigma_product-recent-content h6 {
    margin-bottom: 5px;
    font-weight: 600;
}

.sigma_product-recent button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    margin-left: auto;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #F0F2F3;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    width: 40px;
    height: 40px;
}

/* 6.5. Banners */
.sigma_banner .slick-track,
.sigma_banner .slick-slide {
    margin: 0;
}

.sigma_banner {
  position: relative;
  padding: 0;
  background-color: #F0F2F3;
  border-radius: 8px;
}

.sigma_banner h1 {
    font-size: 80px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
}

.sigma_banner .badge{
  margin-bottom: 10px;
}
.sigma_banner.banner-3 .stroke-text{
  font-size: 80px;
  position: absolute;
  left: 60px;
  top: 40px;
  width: 10px;
  word-break: break-all;
  line-height: 0.8;
}

.sigma_banner p:first-child {
    margin-bottom: 10px;
}

.sigma_banner p {
    margin-bottom: 25px;
    color: #fff;
    max-width: 600px;
}

.sigma_banner .slick-dots {
    padding: 0 0 10px;
}

.sigma_banner .slick-dots li button {
    background-color: #fff;
}

.sigma_banner .slick-dots li.slick-active button {
    background-color: #ff9044;
}

.sigma_banner .sigma_banner-slider-inner .sigma_banner-text {
    position: relative;
    z-index: 1;
}

.banner-1.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 170px 0 220px;
}

.sigma_banner.banner-1 .sigma_arrows,
.sigma_banner.banner-3 .sigma_arrows{
  position: absolute;
  bottom: 50px;
  right: 30px;
  padding: 20px 0;
  z-index: 1;
}
.sigma_banner.banner-1 .slick-dots{
  position: absolute;
  bottom: 70px;
  left: 30px;
  padding: 0;
}
.sigma_banner.banner-1 .slick-dots li button{
  background-color: #5f5f5f;
}
.sigma_banner.banner-1 .slick-dots li.slick-active button{
  background-color: #ff9044;
}

/* Style 2 */


/* Style 3 */
.banner-3.sigma_banner .slick-track {
  display: flex;
}

.banner-3.sigma_banner .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.banner-3.sigma_banner .slick-track,
.banner-3.sigma_banner {
    padding: 0;
}

.banner-3.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 210px 0;
}

.banner-3.sigma_banner .slick-slider:hover .slick-prev {
    left: 70px;
}

.banner-3.sigma_banner .slick-slider:hover .slick-next {
    right: 70px;
}

.dot-pattern::after {
    background: #000 url(../img/pattarn.png);
    content: "";
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
}

.sigma_banner-animation {
    animation: bannerscale 10s linear infinite;
}

/* 6.6. Icon Blocks */
.sigma_block-box,
.sigma_icon-block {
    padding: 20px;
    position: relative;
    margin-bottom: 30px;
    border: 1px solid #F0F2F3;
    background-color: #fff;
    border-radius: 8px;
    z-index: 1;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    transition: .3s;
}

.sigma_block-box.crayon-texture-2,
.sigma_icon-block.crayon-texture-2{
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.sigma_icon-block i {
    font-size: 40px;
    line-height: 1;
    margin: 0 0 15px;
    color: #838383;
    display: inline-block;
}

.sigma_icon-block.has-link:hover {
    background-color: #F0F2F3;
}

.sigma_icon-block a {
    display: block;
}

.sigma_icon-block a h5 {
    font-size: 14px;
}

.sigma_icon-block h5 {
    font-size: 18px;
    margin-bottom: 0;
}

.sigma_icon-block h5+p {
    margin-top: 10px;
}

.sigma_icon-block p {
    margin-bottom: 0;
}

.sigma_icon-block svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.sigma_icon-block .sigma_svg-stroke-shape-anim {
    stroke-dasharray: 100 1000;
    stroke-dashoffset: -620;
    stroke: #ff9044;
    stroke-width: 5px;
    fill: transparent;
    -webkit-transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    width: 100%;
    height: 100%;
}

.sigma_icon-block:hover .sigma_svg-stroke-shape-anim {
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke-dasharray: 1110;
}

/* Style 2 */
.sigma_icon-block.icon-block-2 {
    padding: 0;
    background-color: #fff;
    border: 0;
    box-shadow: none;
}

.sigma_icon-block.icon-block-2 i {
    color: #ff9044;
}

/* Style 3 */
.sigma_icon-block.icon-block-3{
  display: flex;
  align-items: flex-start;
  padding: 15px;
}
.sigma_icon-block.icon-block-3 i{
  margin: 0;
  font-size: 30px;
  width: 50px;
  height: 50px;
  background-color: #ff9044;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 10px;
}
.sigma_icon-block.icon-block-3 h5+p{
  margin-top: 0;
}
.sigma_icon-block.icon-block-3 .sigma_icon-block-content{
  flex: 1;
}

/* Style 4 */
.sigma_icon-block.icon-block-4{
  border: 0;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: flex-start;
  padding: 0;
}
.sigma_icon-block.icon-block-4 i{
  position: relative;
  margin-right: 15px;
  margin-bottom: 0;
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #F0F2F3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9044;
}
.sigma_icon-block.icon-block-4 i::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 1px;
  background-color: #F0F2F3;
}
.sigma_icon-block.icon-block-4 .sigma_icon-block-content{
  flex: 1;
}

/* Style 5 */
.sigma_icon-block.icon-block-5{
  padding: 30px;
}

/* 6.7. Call to Actions */
.sigma_cta-notice {
    padding: 40px;
    border-radius: 8px;
}

.sigma_cta-notice-inner {
    max-width: 600px;
}

.sigma_cta-notice-inner p {
    margin-bottom: 25px;
}


.sigma_cta {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    overflow: hidden;
}

.sigma_cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 8px;
    transition: .3s;
}

.sigma_cta:hover::before {
    background-color: rgba(0, 0, 0, .3);
}

.sigma_cta img {
    border-radius: 8px;
    width: 100%;
}

.sigma_cta-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 20px;
}

.sigma_cta-content h4 {
    margin-bottom: 10px;
}

.sigma_cta-content p {
    margin-bottom: 30px;
    max-width: 350px;
}

/* 6.8. Login & Signup */
.sigma_auth-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
    box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
}

.sigma_auth-description {
    width: 600px;
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sigma_auth-description-inner {
    text-align: center;
    max-width: 400px;
}

.sigma_auth-description-inner h2 {
    margin: 0;
    color: #fff;
}

.sigma_auth-description-inner p {
    margin: 30px 0;
    color: #fff;
}

.sigma_auth-description-inner i {
    color: #fff;
    line-height: 1;
    font-size: 40px;
    margin-bottom: 30px;
    display: block;
}

.sigma_social-login {
    margin-bottom: 20px;
}

.sigma_social-login+p a {
    display: inline-block;
}

.sigma_auth-form {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #F0F2F3;
    padding: 60px;
    text-align: center;
}

.sigma_auth-form h2 {
    margin-bottom: 50px;
}

.sigma_auth-form form {
    max-width: 400px;
    margin: 0 auto;
}

.sigma_auth-form .sigma_btn-custom {
    margin-top: 20px;
}

.sigma_auth-form a {
    display: block;
    color: #1f1f1f;
    text-decoration: underline;
}

.sigma_auth-seperator {
    margin: 30px 0;
    text-align: center;
}

.sigma_auth-seperator span {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #9F9E9E;
    font-size: 13px;
    font-weight: 500;
}

.sigma_auth-seperator span::before {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    height: 1px;
    margin-right: 20px;
}

.sigma_auth-seperator span::after {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    height: 1px;
    margin-left: 20px;
}

.sigma_social-login-btn {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    line-height: 24px;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 20px;
    border-radius: 3px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-radius: 25px;
}

.sigma_social-login-btn i {
    margin-right: 5px;
}

.sigma_auth-mini {
    background-color: #F0F2F3;
    padding: 20px;
    border-radius: 8px;
}

.sigma_auth-mini h5 {
    margin-bottom: 15px;
}

.sigma_auth-mini a {
    display: block;
    color: #1f1f1f;
    text-decoration: underline;
}

.sigma_auth-mini a:hover {
    color: #ff9044;
}

/* 6.9. Error 404 */
.sigma_404-container {
    text-align: center;
}

.sigma_404-container img {
    margin-bottom: 30px;
}

.sigma_404-container p {
    max-width: 500px;
    margin: 0 auto 20px;
}

/* 6.10. Testimonials */
.sigma_testimonials .slick-dots {
    justify-content: center;
    margin: 10px 0 0;
}

.sigma_testimonials-inner {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    text-align: center;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
    border: 1px solid #F0F2F3;
    border-radius: 8px;
}

.sigma_testimonials-inner::before {
    content: '\f113';
    font-family: flaticon;
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 48px;
    line-height: 1;
    opacity: .3;
}

.sigma_testimonials-inner img {
    border-radius: 50%;
    width: 100px;
    margin: 0 auto 20px;
}

.sigma_testimonials-inner span {
    display: block;
    margin: 0 0 10px;
    font-weight: 600;
}

.sigma_testimonials-inner p {
    margin: 0;
}

.sigma_testimonials-inner h5 {
    margin-bottom: 10px;
}

.sigma_testimonials .sigma_sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 8px 8px;
}

.sigma_testimonials .sigma_sm li+li {
    margin: 0;
}

.sigma_testimonials .sigma_sm li {
    flex: 1;
}

.sigma_testimonials .sigma_sm li:first-child a {
    border-radius: 0 0 0 8px;
}

.sigma_testimonials .sigma_sm li:last-child a {
    border-radius: 0 0 8px 0;
}

.sigma_testimonials .sigma_sm li a {
    background-color: #F0F2F3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #838383;
}

.sigma_testimonials .sigma_sm li a:hover {
    background-color: #1f1f1f;
    color: #fff;
}

/* Style 2 */
.sigma_testimonial-body {
    position: relative;
    border-radius: 8px;
    background-color: #F0F2F3;
    padding: 20px;
}

.sigma_testimonial-body::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #F0F2F3;
}

.sigma_testimonial-body h5 {
    margin-bottom: 5px;
}

.sigma_testimonial-body p {
    margin-bottom: 0;
}

.sigma_testimonial-body::after {
    content: '\f113';
    position: absolute;
    font-family: flaticon;
    font-size: 34px;
    opacity: .3;
    display: block;
    top: 20px;
    right: 20px;
}

.sigma_testimonial-author {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_testimonial-author img {
    width: 80px;
    border-radius: 50%;
    margin-right: 15px;
}

.sigma_testimonial-author-inner {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.sigma_testimonial-author-inner h6 {
    margin-bottom: 0;
}

.sigma_testimonial-author-inner span {
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    color: #848486;
}

/* 6.11. Mission/Vison */
.sigma_image-wrap .sigma_image-box{
  padding: 0 15px;
}
.sigma_image-wrap .sigma_image-box img{
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}

/* 6.12 Team Members */
.sigma_team{
  position: relative;
  background-color: #fff;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  margin-bottom: 30px;
  border-bottom: 4px solid #ff9044;
}
.sigma_team-thumb{
  overflow: hidden;
}
.sigma_team-thumb img{
  width: 100%;
  transition: .3s;
}
.sigma_team-thumb:hover img{
  transform: scale(1.1);
}
.sigma_team-body{
  text-align: center;
  padding: 20px;
}

.sigma_team-info h5{
  margin-bottom: 5px;
}
.sigma_team-info p{
  font-size: 15px;
  line-height: 24px;
  color: #ff9044;
  padding: 0;
}
.sigma_team-info{
  margin: 0 0 15px;
}
.sigma_team-sm .sigma_sm{
  justify-content: center;
}
.sigma_team-sm .sigma_sm li a{
  display: flex;
  font-size: 12px;
}
.sigma_team-sm .sigma_sm li + li{
  margin-left: 15px;
}
.sigma_team-sm .sigma_sm .sigma_round-button svg{
  width: 30px;
  height: 30px;
}

/* Style 2 */
.sigma_team.team-2{
  background-color: transparent;
  border-bottom: 0;
  z-index: 1;
}
.sigma_team.team-2 .sigma_team-body{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.sigma_team.team-2:hover::before,
.sigma_team.team-2:hover .sigma_team-body{
  opacity: 1;
  visibility: visible;
}
.sigma_team.team-2::before{
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba( 2, 21, 47, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}

.sigma_team.team-2 .sigma_team-sm .sigma_sm li a,
.sigma_team.team-2 .sigma_team-info p,
.sigma_team.team-2 .sigma_team-info h5{
  color: #fff;
}

.sigma_team.team-2 .sigma_round-button .sigma_round-button-stroke,
.sigma_team.team-2 .sigma_round-button .sigma_round-button-circle{
  stroke: #fff;
}

/* Style 3 */
.sigma_team.team-3{
  padding: 20px;
  border-bottom: 0;
  text-align: center;
  border: 1px solid #F0F2F3;
}
.sigma_team.team-3 .sigma_team-body{
  padding: 0;
}
.sigma_team.team-3 .sigma_team-thumb{
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.sigma_team.team-3 .sigma_team-thumb::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px );
  height: calc(100% - 20px );
  margin: 10px;
  border-radius: 50%;
  background-color: #1f1f1f;
  opacity: 0;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  transform: scale(0);
}
.sigma_team.team-3 .sigma_team-thumb::after{
  content: "\f067";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  font-size: 20px;
  transition: .3s;
  transform: translate(-50%, -50%) scale(0);
}
.sigma_team.team-3:hover .sigma_team-thumb::before{
  transform: scale(1);
  opacity: .6;
  visibility: visible;
}
.sigma_team.team-3:hover .sigma_team-thumb::after{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_team.team-3 .sigma_team-thumb:hover img{
  transform: scale(1);
}
.sigma_team.team-3 .sigma_team-thumb img{
  border-radius: 50%;
  width: 120px;
  margin: 0 auto;
}
.sigma_team.team-3 .sigma_team-info img{
  width: 30px;
  margin-bottom: 20px;
}
.sigma_team.team-3 .sigma_team-info i{
  font-size: 24px;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
}

/* 6.13. Pricing */
.sigma_pricing{
  background-image: url(../img/pricing.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 26px 20px 44px 20px;
  margin-bottom: 30px;
}
.sigma_pricing .sigma_pricing-icon{
  margin-bottom: 18px;
}
.sigma_pricing-title{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}
.sigma_pricing-price{
  position: relative;
  display: inline-block;
  line-height: 1;
}
.sigma_pricing-price .sigma_pricing-currency{
  position: absolute;
  top: 0;
  left: -12px;
  font-size: 20px;
  font-weight: 400;
}
.sigma_pricing-price span{
  font-size: 48px;
  font-weight: 800;
  color: #ff9044;
}
.sigma_pricing .list-style-none{
  margin-top: 20px;
}
.sigma_pricing .sigma_btn-custom{
  margin-top: 35px;
}

.sigma_pricing .list-style-none li i{
  margin-right: 10px;
}

/* 6.14. Timeline */
.sigma_timeline-nodes {
  margin-bottom: 50px;
  position: relative;
}
.sigma_timeline-nodes:nth-child(even) {
  flex-direction: row-reverse;
}
.sigma_timeline-nodes:nth-child(even) .sigma_timeline-date {
  text-align: right;
}
.sigma_timeline-nodes::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 49.99999%;
  width: 0;
  border-left: 1px solid #ff9044;
  opacity: .3;
  height: calc(100% + 50px);
  z-index: 1;
  transform: translateX(-50%);
}
.sigma_timeline-nodes:last-child::before{
  display: none;
}
.sigma_timeline-element h4 {
  font-size: 24px;
  line-height: 45px;
  font-weight: 600;
}
.sigma_timeline-nodes:nth-child(odd) h4,
.sigma_timeline-nodes:nth-child(odd) p {
  text-align: right;
}
.sigma_timeline-nodes:nth-child(odd) h4,
.sigma_timeline-nodes:nth-child(odd) p{
  text-align: right;
}
.sigma_timeline-element p,
.sigma_timeline-element time {
  color: #555b62;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
}
.sigma_timeline-image {
  position: relative;
  z-index: 2;
}
.sigma_timeline-image i {
  background: #fff;
  padding: 5px;
  color: #ff9044;
  margin-top: 0;
  font-size: 24px;
}
.sigma_timeline-nodes:nth-child(odd) .sigma_timeline-date{
  text-align: left;
}
.sigma_timeline-date span {
  font-size: 20px;
  font-weight: 700;
  color: #1f1f1f;
}
.sigma_timeline-date span.sigma_timeline-month {
  text-align: center;
  line-height: 20px;
  color: #555b62;
  margin-top: 8px;
  font-size: 14px;
  text-transform: uppercase;
}

/* 6.15. Contact */
.sigma_contact-wrap{
  margin: 0 auto;
  max-width: 950px;
  position: relative;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}
.sigma_contact-info {
  padding: 45px 30px;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  z-index: 1;
}
.sigma_contact-info:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url(../img/comment_icon.png) no-repeat center;
}
.sigma_contact-info-inner p:last-child{
  margin-bottom: 0;
}
.sigma_contact-info h4{
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 15px;
}
.sigma_contact-info h4 span {
    display: block;
    font-size: 15px;
    font-weight: 400;
}
.sigma_contact-info p{
  color: #fff;
}
.sigma_contact-info p span {
  display: block;
  font-size: 20px;
}
.sigma_contact-form{
  padding: 45px 45px 45px 30px;
}


/*======================
7. Misc
========================*/
.slick-list {
    margin: 0 -15px;
}

.slick-slide {
    outline: none;
    margin: 0 15px;
}

/* Arrows */
.sigma_arrows {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.sigma_arrows .slick-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: .3s;
    cursor: pointer;
    color: #1f1f1f;
    border-radius: 50%;
}

.sigma_arrows .slick-arrow:hover {
    color: #fff;
    background-color: #ff9044;
}
.sigma_arrows.sigma_arrows-transparent .slick-arrow:hover,
.sigma_arrows.sigma_arrows-transparent .slick-arrow{
  background-color: transparent;
  color: #fff;
}

.sigma_arrows.sigma_arrows-dark .slick-arrow:hover,
.sigma_arrows.sigma_arrows-dark .slick-arrow{
  color: #1f1f1f;
}
.sigma_arrows.sigma_arrows-dark .sigma_round-arrow .sigma_round-arrow-circle,
.sigma_arrows.sigma_arrows-dark .sigma_round-arrow .sigma_round-arrow-stroke{
  color: #1f1f1f;
  stroke: #1f1f1f;
}
.sigma_arrows.sigma_arrows-sm .sigma_round-arrow svg{
  width: 40px;
  height: 40px;
}

.sigma_arrows .slick-arrow.slider-prev {
    margin-right: 10px;
}
.sigma_arrows .sigma_round-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sigma_arrows .sigma_round-arrow svg{
  width: 50px;
  height: 50px;
}
.sigma_arrows .sigma_round-arrow.prev-arrow{
  margin-right: 10px;
}
.sigma_arrows .sigma_round-arrow i{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}
.sigma_arrows .sigma_round-arrow .slick-arrow.slider-prev{
  margin: 0;
}

.sigma_arrows .sigma_round-arrow .sigma_round-arrow-stroke{
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #fff;
}
.sigma_arrows .sigma_round-arrow .sigma_round-arrow-circle{
  fill: none;
  stroke: currentColor;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #fff;
}
.sigma_arrows .sigma_round-arrow:hover .sigma_round-arrow-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}
.sigma_arrows-absolute-center .sigma_round-arrow{
  position: absolute;
  top: 50%;
  margin-right: 0;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.sigma_arrows-absolute-center .sigma_round-arrow.prev-arrow{
  left: 35px;
}
.sigma_arrows-absolute-center .sigma_round-arrow.next-arrow{
  right: 35px;
}
.section:hover .sigma_arrows-absolute-center .sigma_round-arrow.next-arrow{
  right: 15px;
  opacity: 1;
  visibility: visible;
}
.section:hover .sigma_arrows-absolute-center .sigma_round-arrow.prev-arrow{
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.slick-prev,
.slick-next {
    background-color: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #1f1f1f;
    transition: .3s;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
}

.slick-slider:hover .slick-next,
.section:hover .slick-next {
    right: -55px;
    opacity: 1;
    visibility: visible;
}

.slick-slider:hover .slick-prev,
.section:hover .slick-prev {
    left: -55px;
    opacity: 1;
    visibility: visible;
}

.slick-prev:before,
.slick-next:before {
    color: #ffffff;
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    font-size: 14px;
}

.slick-prev:before {
    content: "\f053";
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: #fff;
    background-color: #ff9044;
}

button .slick-next .slick-arrow{
  display: none !important;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
    color: #fff;
}

/* Dots */
.slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.slick-dots li {
    margin: 0 5px;
    text-align: center;
}

.slick-dots li button {
    font-size: 0;
    width: 12px;
    height: 12px;
    background-color: #F0F2F3;
    border: 0;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
}

.slick-dots li.slick-active button {
    background-color: #1f1f1f;
}

.slick-slide {
    outline: none;
}

/* custom Dots */
.slick-dots .sigma_round-dot{
  position: relative;
  cursor: pointer;
}
.slick-dots .sigma_round-dot::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  transition: .3s;
  opacity: .6;
}
.slick-dots .sigma_round-dot svg{
  width: 30px;
  height: 30px;
}
.slick-dots .sigma_round-dot .sigma_round-dot-stroke{
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #fff;
}
.slick-dots .sigma_round-dot .sigma_round-dot-circle{
  fill: none;
  stroke: currentColor;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #fff;
}
.slick-dots .slick-active .sigma_round-dot-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}
.slick-dots .slick-active .sigma_round-dot::before,
.slick-dots .sigma_round-dot:hover::before{
  opacity: 1;
}

/* pagination */
.pagination {
    margin-top: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.pagination .page-item {
    margin: 0;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.pagination .page-item .page-link {
    border-radius: 0;
}

.pagination .page-link {
  color: #1f1f1f;
  border: 0;
  background-color: #fff;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border: 1px solid #eee;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  margin-left: 15px;
}

.pagination .page-item.active .page-link {
    background-color: #ff9044;
    border: 1px solid #eee;
}

.pagination .page-item:not(.active) .page-link:hover,
.pagination .page-item:not(.active) .page-link:focus {
    color: #ff9044;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tooltip {
    font-family: 'Open Sans', monospace;
    font-size: 13px;
}

.load-more {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    padding: 10px;
    border: 1px solid #F0F2F3;
    border-radius: 8px;
    font-weight: 600;
    background-color: #fff;
}

.load-more:hover {
    background-color: #F0F2F3;
}

/* Accordion */
.accordion .card {
    margin-bottom: 0;
}

.accordion .card .card-header {
    position: relative;
    background-color: #fff;
    border-bottom: 0;
    padding: .75rem 1.25rem;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: none;
    font-weight: 700;
    color: #1f1f1f;
}

.accordion .card-header[aria-expanded="true"] {
    background-color: #f8f9fa;
    color: #1f1f1f;
}

.accordion .card-header::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
}

.accordion .card-header::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.accordion .card-header[aria-expanded="true"]::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
}

.accordion .card-header[aria-expanded="true"]::after,
.accordion .card-header[aria-expanded="true"]::before {
    background-color: #1f1f1f;
}

.accordion .card-header i {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    font-size: 20px;
}

.accordion .card-header[aria-expanded="true"] i {
    color: #ff9044;
}

.accordion .card {
    border: #F0F2F3;
}

.accordion.with-gap .card-header {
    border-radius: 3px 3px;
}

.accordion.with-gap .card {
    margin-bottom: 20px;
}

.accordion.with-gap .card:last-child {
    margin-bottom: 0;
}

.accordion.with-gap .card:first-of-type,
.accordion.with-gap .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #F0F2F3;
}

/* Rating */
.sigma_rating-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_rating-wrapper>span {
    margin-left: 10px;
    color: #848486;
    font-size: 13px;
}

.sigma_rating {
    margin-bottom: 5px;
}

.sigma_rating i {
    margin-right: 5px;
    font-size: 12px;
    color: #848486;
}

.sigma_rating i.active {
    color: #ffa80a;
}

.mt-negative {
    margin-top: -50px;
    position: relative;
    z-index: 1;
}
.section.section-padding.mt-negative,
.section.mt-negative{
  padding-top: 0;
}

.custom_flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.sigma_block-box {
    padding: 45px 40px;
    border-radius: 0;
    margin-bottom: 30px;
}

.vertical-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sigma_block-box h5 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
}

.sigma_block-box h4 {
    margin-bottom: 10px;
}
.sigma_block-box img{
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.sigma_block-box .sigma_audio-player{
  margin-bottom: 0;
}
.sigma_block-box .sigma_block-content {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sigma_block-box .sigma_block-content li{
  margin: 0;
}
.sigma_block-box .sigma_block-content li + li{
  margin-top: 10px;
}

.sigma_event-box .sigma_event-wrap .vertical-between>.sigma_event-date,
.sigma_block-box .sigma_block-date {
  width: 80px;
  height: 80px;
  display: flex;
  margin-right: 20px;
  font-family: 'Open Sans', serif;
  text-transform: uppercase;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffebdd;
}
.sigma_event-box .sigma_event-wrap .vertical-between>.sigma_event-date>span,
.sigma_block-box .sigma_block-date span {
    display: block;
    font-size: 30px;
    line-height: 1.2;
}

.sigma_event-box .sigma_event-wrap>.vertical-between>.sigma_event-content,
.sigma_block-box .sigma_block-text {
    width: calc(100% - 100px);
}

.sigma_sermon-box .sigma_sermon-wrap .sigma_block-icons>li,
.sigma_block-box .sigma_block-icons>li {
    margin-right: 15px;
}
.sigma_sermon-box .sigma_sermon-wrap .sigma_block-icons>li>a,
.sigma_block-box .sigma_block-icons>li>a {
    width: 40px;
    height: 40px;
    background-color: #ffebdd;
}
.sigma_sermon-box .sigma_sermon-wrap .sigma_block-icons>li>a:hover,
.sigma_block-box .sigma_block-icons>li>a:hover{
  color: #fff;
  background-color: #ff9044;
}

.sigma_audio-player {
  width: 100%;
  font-size: 13px;
  display: flex;
  align-items: flex-end;
  padding: 30px 20px 20px;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
}
.sigma_audio-player .timeline-wrap{
  flex: 1;
  margin: 0 15px 0 0;
}
.sigma_audio-player .timeline {
    background: #f1f1f1;
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 10px;
}

.sigma_audio-player .timeline .progress {
    background: #ff9044;
    width: 0%;
    height: 100%;
    transition: 0.3s;
    border-radius: 0;
}

.sigma_audio-player .controls {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.sigma_audio-player .controls.play-controls{
  position: absolute;
  top: -25px;
  left: 15px;
}
.sigma_audio-player .controls.play-controls .toggle-play{
  width: 50px;
  height: 50px;
  background-color: #ff9044;
  color: #fff;
}
.sigma_audio-player .controls.play-controls .toggle-play:hover{
  background-color: #ff7c22;
}
.sigma_audio-player .controls>* {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sigma_audio-player .controls .toggle-play,
.sigma_audio-player .controls .volume-button{
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: .3s;
}
.sigma_audio-player .controls .volume-button:hover,
.sigma_audio-player .controls .toggle-play:hover{
  background-color: #e8e8e8;
}
.sigma_audio-player .controls .volume-button i,
.sigma_audio-player .controls .toggle-play i{
  transition: .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}
.sigma_audio-player .controls .toggle-play.play i.pause-trigger{
  transform: translate(-50%, -50%) scale(0);
}
.sigma_audio-player .controls .toggle-play.pause i.play-trigger{
  transform: translate(-50%, -50%) scale(0);
}

.sigma_audio-player .controls .toggle-play.pause:hover::before,
.sigma_audio-player .controls .toggle-play.pause:hover::after {
  background-color: #1f1f1f;
}

.sigma_audio-player .time {
    display: flex;
    align-items: center;
}
.sigma_audio-player .time .divider{
  padding: 0 2px;
}

.sigma_audio-player .controls .volume-container {
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.sigma_.audio-player .controls .volume-container .volume-button {
    height: 26px;
    display: flex;
    align-items: center;
}

.sigma_audio-player .controls .volume-container .volume-button .volume {
    font-size: 14px;
}

.sigma_audio-player .controls .volume-container .volume-slider {
  position: absolute;
  left: 50%;
  bottom: calc( 100% + 10px );
  z-index: -1;
  width: 15px;
  height: 0;
  background: #f1f1f1;
  transition: .3s;
  margin-left: -7.5px;
}

.sigma_audio-player .controls .volume-container .volume-slider .volume-percentage {
    background: #ff9044;
    height: 75%;
    width: 15px;
}

.sigma_audio-player .controls .volume-container:hover .volume-slider {
  height: 120px;
}

/*Sigma about us*/
.sigma_about {
    background-position: center;
    background-size: 110%;
}

.sigma_about .sigma_about-content {
    text-align: center;
}

.sigma_about .sigma_about-text {
    position: relative;
    font-size: 20px;
}

/*Causes section*/
.sigma_causes-content .sigma_causes-chart {
    flex-wrap: wrap;
}

.sigma_causes-content .sigma_causes-chart p {
    margin: 0;
}

.sigma_causes-content .sigma_causes-chart .chart-style {
    margin: 0 20px 0;
    position: relative;
}

.sigma_causes-content .sigma_causes-chart .chart-style .percent {
    font-family: 'Open Sans', serif;
    color: #fff;
    font-size: 40px;
    font-weight: 800;
    line-height: 1;
    margin-top: -4px;
}
.sigma_causes-content .sigma_causes-chart .chart-style > div{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.sigma_causes-content .sigma_causes-chart .chart-style .percent + span{
  color: #fff;
  margin-left: 4px;
}

/*Video box*/
.sigma_video-popup-wrap{
  position: relative;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.sigma_video-popup-wrap .sigma_video-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.sigma_video-popup{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}

.sigma_video-popup::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ff9044;
  transition: .3s;
  z-index: -1;
}
.sigma_video-popup:hover{
  color: #fff;
}
.sigma_video-popup:hover::after{
  width: 100%;
}

/*Event */

.sigma_event-box .sigma_event-wrap {
    margin-bottom: 0;
    height: 570px;
    overflow: hidden;
}

.sigma_event-box .sigma_event-wrap .vertical-between {
    border: 1px solid #eee;
    margin-bottom: 30px;
    padding: 20px 25px;
}

.sigma_event-box .sigma_event-wrap .vertical-between>.sigma_event-date {
    width: 80px;
    margin-right: 15px;
}

.sigma_event-box .sigma_event-wrap .vertical-between>.sigma_event-content {
    width: calc(100% - 75px);
}
.sigma_event-box .sigma_event-wrap .vertical-between>.sigma_event-content h5{
  font-weight: 600;
}

.sigma_sermon-box .sigma_sermon-wrap {
    margin-bottom: 0;
}
.sigma_sermon-box .sigma_sermon-wrap>li{
    margin-top: 0;
}
.sigma_sermon-box .sigma_sermon-wrap .card {
    border: none;
    border-radius: 0;
}

.sigma_sermon-box .sigma_sermon-wrap .card .card-header {
    border: none;
    border-radius: 0;
    padding: 0;
    background: transparent;
}

.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  border-bottom: 1px solid #eee;
  font-family: 'Open Sans', serif;
  font-size: 18px;
  margin-bottom: 10px;
  color: #1f1f1f;
  font-weight: 600;
  padding-right: 40px;
}

.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link::before{
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1.25rem;
  width: 10px;
  height: 2px;
  background-color: #1f1f1f;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 1;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link::after{
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1.25rem;
  width: 10px;
  height: 2px;
  background-color: #1f1f1f;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 1;
}

.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link.collapsed::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
}


.sigma_sermon-box .sigma_sermon-wrap li:last-child .card .card-header .btn-link{
  border-bottom: 0;
}

.sigma_sermon-box .sigma_sermon-wrap .card .card-body{
    padding: 0;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-body img{
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link.collapsed{
    margin-bottom: 0;
}

.bg-expand{
    position: relative;
}
.bg-expand:after{
    content: '';
    position: absolute;
    left: 100%;
    right: 0;
    width: 1000px;
    height: 100%;
    background: #1f1f1f;
    top: 0;
}
.bg-expand.left-expand:after{
    left: 100%;
    right: 0;
}
.bg-expand.right-expand:after{
    right: 100%;
    left: auto;
}
.bg-expand.primary:after{
    background: #ff9044;
}
.bg-expand.secondary:after{
    background: #1f1f1f;
}
.sigma_spacer-sm{
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

/*sigma_testimonials*/
.sigma_testimonial{
    padding: 30px 40px;
}
.sigma_testimonial .sigma_testimonial-inner>i{
    font-size: 50px;
    margin-bottom: 25px;
}
.sigma_testimonial .sigma_testimonial-inner>p{
    font-size: 15px;
    line-height: 30px;
    margin: 0 auto 20px;
}
.sigma_testimonial .sigma_testimonial-inner cite{
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
}
.sigma_testimonial .slick-dots {
    justify-content: flex-end;
    margin: -35px 0 0;
}
.sigma_testimonial .slick-dots li button{
    width: 12px;
    height: 6px;
    border-radius: 10px;
    transition: 0.3s all;
}
.sigma_testimonial .slick-dots li.slick-active button{
    transform: scale(1);
    width: 16px;
    background-color: #ff9044;
}

/* Sermons */
.sigma_post.sigma_post-list.sigma_sermon{
  padding: 30px;
}
.sigma_post.sigma_post-list.sigma_sermon .sigma_post-thumb{
  flex: 0 0 150px;
  margin-right: 20px;
}
.sigma_post.sigma_post-list.sigma_sermon .sigma_post-body{
  padding: 0;
}
.sigma_post.sigma_post-list.sigma_sermon .sigma_post-body h5{
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.4;
}
.sigma_sermon-icons{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 0;
}
.sigma_sermon-icons li{
  margin: 0 15px 0 0;
}
.sigma_sermon-icons li a{
  width: 40px;
  height: 40px;
  background-color: #ffebdd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_sermon-icons li a:hover{
  background-color: #ff9044;
  color: #fff;
}
.sigma_sermon-meta{
  margin: 0;
  display: flex;
  align-items: center;
}
.sigma_sermon-meta img{
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.sigma_sermon-meta li{
  margin-top: 0;
}
.sigma_sermon-meta li + li{
  margin-left: 20px;
}

/* Sermon Details */
.sigma_post-single-thumb-icons{
  position: absolute;
  text-align: center;
  bottom: 0;
  padding: 0;
  left: 0;
  width: 100%;
  background: rgba(31,31,31, 0.8);
}
.sigma_post-single-thumb-icons ul{
  margin: 0 auto;
}
.sigma_post-single-thumb-icons ul li {
  float: left;
  width: 25%;
  margin: 0 auto;
  transition: .3s;
}
.sigma_post-single-thumb-icons ul li + li{
  border-left: rgba(255, 255, 255, 0.2) solid 1px;
}
.sigma_post-single-thumb-icons ul li :hover{
  background-color: #1f1f1f;
}
.sigma_post-single-thumb-icons ul li a{
  color: #fff;
  display: block;
  padding: 25px 20px;
  border: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}

/* Events */
.sigma_event{
  position: relative;
  margin: 0 auto 50px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.sigma_event-date{
  width: 80px;
  height: 80px;
  display: flex;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffebdd;
}
.sigma_event-date span{
  display: block;
  font-size: 30px;
  line-height: 1.2;
  color: #ff9044;
}
.sigma_event-date.date-transparent{
  background-color: transparent;
  justify-content: flex-start;
  align-items: flex-start;
}
.sigma_event-thumb{
  flex: 0 37%;
  margin-right: 20px;
}
.sigma_event-body{
  flex: 1;
}
.sigma_event-body ul li i{
  width: 20px;
}
.sigma_event-thumb{
  overflow: hidden;
}
.sigma_event-thumb img{
  transition: .3s;
}
.sigma_event-thumb:hover img{
  transform: scale(1.1);
}
.sigma_post-single-thumb .sigma_event-date{
  position: absolute;
  top: 20px;
  left: 20px;
}
.sigma_post-single .sigma_countdown-timer{
  background-color: #ffebdd;
  border: 0;
  color: #ff9044;
  margin: 0;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  left: 20px;
}
.sidebar-widget.widget-event-details ul{
  margin: 0;
}
.sidebar-widget.widget-event-details ul li{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-widget.widget-event-details ul li + li{
  margin-top: 10px;
}
.sidebar-widget.widget-event-details ul li span{
  font-weight: 700;
  color: #1f1f1f;
}

.widget-recent-events .sigma_recent-post{
  display: flex;
  align-items: center;
}

.widget-recent-events .sigma_recent-post-body{
  flex: 1;
}
.widget-recent-events .sigma_recent-post-body > a{
  color: #707070;
  font-size: 14px;
}
.widget-recent-events .sigma_recent-post-body > a:hover{
  color: #ff7c22;
}

.widget-recent-events .sigma_recent-post h6 {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 15px;
}

.widget-recent-events .sigma_recent-post h6 a {
    color: #1f1f1f;
}

.widget-recent-events .sigma_recent-post h6 a:hover,
.widget-recent-events .sigma_recent-post span a:hover {
    color: #ff9044;
}

.widget-recent-events .sigma_recent-post span a {
    font-weight: 400;
    text-decoration: underline;
    color: #1f1f1f;
}

.widget-recent-events .sigma_recent-post+.sigma_recent-post {
    margin-top: 30px;
}

.widget-recent-events .sigma_event-date{
  margin-right: 15px;
  width: 60px;
  height: 60px;
  font-size: 13px;
}
.widget-recent-events .sigma_event-date span{
  font-size: 20px;
}

/* Prayers */
.sigma_post.sigma_post-list.sigma_prayer{
  display: block;
  padding: 20px;
}
.sigma_post.sigma_post-list.sigma_prayer .sigma_post-head{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.sigma_post.sigma_post-list.sigma_prayer .sigma_post-body{
  padding: 0;
}
.sigma_post.sigma_post-list.sigma_prayer .sigma_post-single-author{
  margin-bottom: 20px;
}

/* Donations */
.sigma_post.sigma_donation .sigma_post-body{
  margin-top: 0;
  margin-left: 0;
  width: 100%;
}
.sigma_post.sigma_donation .sigma_donation-progress{
  background-color: #f1f1f1;
}
.sigma_post.sigma_donation .progress-bar{
  height: 8px;
  background-color: #ff9044;
}
.sigma_post.sigma_donation .sigma_donation-amount{
  text-align: center;
  overflow: hidden;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.sigma_post.sigma_donation .sigma_post-thumb{
  overflow: hidden;
}
.sigma_post.sigma_donation .sigma_post-thumb img{
  transition: .3s;
}
.sigma_post.sigma_donation .sigma_post-thumb:hover img{
  transform: scale(1.1);
}
.sigma_post.sigma_donation .sigma_donation-amount-item{
  flex: 1;
}
.sigma_post.sigma_donation .sigma_donation-amount-item + .sigma_donation-amount-item{
  border-left: 1px solid #d9d9d9;
}
.sigma_post.sigma_donation .sigma_donation-amount-item p{
  color: #555b62;
  font-weight: 600;
  margin: 0;
}
.sigma_post.sigma_donation .sigma_donation-amount-item span{
  font-size: 18px;
  color: #ff9044;
}

/* Donation Details */
.sigma_donation-single .sigma_post-single-thumb{
  margin-bottom: 0;
}
.sigma_donation-single .sigma_icon-block.icon-block-3{
  margin: 0;
}
.select_amount {
  padding: 0;
  margin: 0;
}
.select_amount li {
  display: inline-block;
  vertical-align: top;
  padding: 8px 22px;
  border: #eee solid 1px;
  border-radius: 3px;
  cursor: pointer;
  transition: .3s;
  margin-right: 12px;
}
.select_amount li:hover, .select_amount li:focus, .select_amount li.active {
  background: #ff9044;
  border-color: #ff9044;
  color: #fff;
}

/* Verses */
.sigma_verse-item {
  margin-bottom: 30px;
}
.sigma_verse-item h3{
  position: relative;
  margin: 0;
  color: #fff;
  max-width: 700px;
}
.sigma_verses .section > i{
  position: absolute;
  top: 40px;
  right: 40px;
  color: #fff;
  font-size: 80px;
  line-height: 1;
  opacity: .2;
}
