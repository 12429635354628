/*======================
8. Repsonsive
========================*/

@media(max-width: 1199px) {

  .sigma_auth-description {
    width: 450px;
  }

  .checkout-billing {
    margin-top: 50px;
  }

  .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
    padding: 30px 15px;
  }
  .sigma_header-bottom-inner>.navbar-nav>.menu-item>a,
  .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
    font-size: 18px;
  }

}

@media (max-width: 991px) {

  .section-title .title{
    font-size: 40px;
  }
  .section-title{
    margin-bottom: 30px;
  }
  .section-title.text-center{
    margin: 0 auto 30px;
  }
  .custom-img-1,
  .sigma_grid-slider .slick-prev,
  .sigma_grid-slider .slick-next {
      display: none;
  }
  .sigma_subheader h1{
    font-size: 40px;
  }
  .sigma_subheader .breadcrumb li a{
    font-size: 12px;
  }

  .banner-1.sigma_banner .sigma_banner-slider-inner{
    padding: 90px 0 130px;
    text-align: center;
  }
  .sigma_banner .sigma_banner-slider-inner .sigma_banner-text{
    padding: 0 15px;
  }
  .banner-1.sigma_banner .sigma_banner-slider-inner .sigma_video-popup-wrap img{
    margin: 0 auto;
  }
  .sigma_banner h1{
    font-size: 60px;
  }
  .sigma_banner p{
    margin: 0 auto 25px;
  }
  .sigma_banner.banner-1 .sigma_arrows,
  .sigma_banner.banner-3 .sigma_arrows{
    right: 50%;
    transform: translateX(50%);
  }
  .sigma_banner.banner-1 .sigma_arrows::before{
    display: none;
  }

  .banner-3.sigma_banner .sigma_arrows {
      margin-top: 10px;
  }

  .mb-lg-20 {
      margin-bottom: 20px;
  }

  .mb-lg-30,
  .sigma_upsells {
      margin-bottom: 30px;
  }

  .mr-lg-30 {
      margin-left: 0;
  }

  .section {
      padding: 70px 0;
  }
  .mt-negative{
    margin-top: 0;
    padding-top: 40px;
  }
  .section-button{
    margin-top: 40px;
  }
  .section.section-padding {
      padding: 70px 0 40px;
  }

  .sigma_section-fw {
      padding: 0 15px;
  }

  .checkout-billing {
      margin-top: 40px;
  }

  .navbar-brand {
      width: 150px;
  }

  .sigma_header-controls .aside-toggler {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px solid #F0F2F3;
      border-radius: 8px;
      margin-left: 20px;
  }

  .sigma_header-bottom,
  .sigma_header-bottom .navbar-nav,
  .sigma_header-middle .navbar-nav,
  .sigma_header .sigma_search-adv {
      display: none;
  }

  .sigma_header-controls.style-2 .aside-toggler.style-2.desktop-toggler,
  .aside-toggler.style-2.desktop-toggler{
    display: none;
  }
  .sigma_header-controls.style-2 .aside-toggler.style-2,
  .aside-toggler.style-2{
    display: grid;
  }

  .sigma_header.header-2 .sigma_header-middle .navbar {
      padding: 15px 0;
  }

  .aside-toggler {
      display: block;
  }

  .sigma_aside-overlay.aside-trigger-left,
  .sigma_aside.sigma_aside-left {
      display: block;
  }

  .sigma_cart-sidebar-wrapper.sigma_aside{
    width: 100%;
    max-width: 100%;
  }
  .sigma_cart-sidebar-footer,
  .sigma_cart-sidebar-header{
    padding: 20px;
  }
  .sigma_cart-sidebar-footer,
  .sigma_cart-sidebar-footer a{
    display: block;
  }
  .sigma_cart-sidebar-footer a{
    width: 100%;
    margin-top: 10px;
  }
  .sigma_cart-sidebar-item{
    padding: 20px 0;
  }
  .sigma_cart-sidebar-body{
    padding: 0 20px;
    height: calc( 100% - 159px )
  }
  .sigma_cart-sidebar-footer h4{
    font-size: 16px;
  }
  .sigma_cart-sidebar-item-body img{
    width: 70px;
  }
  .sigma_cart-sidebar-item-body-inner h5{
    font-size: 18px;
  }

  .sigma_post-single .sigma_post-share {
      margin-top: 40px;
      margin-bottom: 30px;
  }

  .sidebar {
      margin-top: 10px;
  }

  .sidebar .sidebar-widget:last-child {
      margin-bottom: 40px;
  }

  .load-more {
      margin: 40px 0 30px;
  }

  .pagination {
      margin: 10px 0 30px;
  }

  .sigma_icon-block svg {
      display: none;
  }

  .sigma_footer-top .container{
    flex-direction: column;
    text-align: center;
  }
  .sigma_footer.footer-2 .sigma_footer-top-item + .sigma_footer-top-item{
    border-left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .sigma_footer.footer-2 .sigma_footer-top-item{
    padding: 15px 0;
    width: 100%;
  }
  .sigma_footer-buttons{
    justify-content: center;
  }
  .sigma_footer.footer-2 .sigma_footer-top-item,
  .sigma_footer.footer-2 .sigma_footer-sm{
    justify-content: center;
  }

  .sigma_instagram {
      max-width: 400px;
      padding: 40px;
  }

  .sigma_post.sigma_post-list {
      padding: 20px;
      align-items: flex-start;
  }

  .sigma_product-additional-info .tab-content {
      margin-top: 30px;
  }

  .sigma_auth-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .sigma_auth-description {
    width: 100%;
  }

  .sigma_companies .sigma_companies-item {
    min-height: 110px;
  }

  .sigma_expaneded-row {
    flex-wrap: wrap;
  }
  .sigma_block-box{
    padding: 25px 20px;
  }
  .sigma_block-box.crayon-texture-2,
  .sigma_icon-block.crayon-texture-2{
    background-image: none;
    border: 1px solid #F0F2F3;
    background-color: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
  }
  .sigma_block-box .sigma_block-content{
    display: block;
  }
  .sigma_event-box .sigma_event-wrap>.vertical-between>.sigma_event-content,
  .sigma_block-box .sigma_block-text{
    width: 100%;
  }

  .sigma_causes-content .sigma_causes-chart p{
    margin: 0 0 20px;
  }
  .sigma_causes-content .sigma_causes-chart{
    display: block;
  }
  .sigma_causes-content .sigma_causes-chart .chart + p{
    margin: 20px 0 0;
  }
  .sigma_sermon-box .sigma_sermon-wrap .card .card-header .card-link{
    font-size: 16px;
  }

}

@media (max-width: 768px) {
  .sigma_img-custom::before{
    display: none;
  }
  blockquote {
    font-size: 14px;
  }

  blockquote p {
      font-size: 14px;
  }

  h1 {
      font-size: 34px;
  }

  h2 {
      font-size: 30px;
  }

  h3 {
      font-size: 26px;
  }

  h4 {
      font-size: 22px;
  }
  .section-title .title{
    font-size: 30px;
  }
  .sigma_banner h1{
    font-size: 50px;
  }
  .sigma_subheader h1{
    font-size: 30px;
  }
  .sigma_subheader .breadcrumb-item+.breadcrumb-item::before{
    padding-right: 5px;
  }
  .sigma_subheader .breadcrumb-item+.breadcrumb-item{
    padding-left: 5px;
  }
  .sigma_responsive-table thead {
      display: none;
  }
  .header-absolute + .sigma_subheader .sigma_subheader-inner{
    padding: 260px 0 30px;
  }
  .sigma_responsive-table td {
      position: relative;
      display: block;
      width: 100%;
      text-align: right;
      padding: 10px;
      border-bottom: 0;
  }

  .sigma_responsive-table tr.total td::before {
      display: none;
  }

  .sigma_responsive-table tr.total td:first-child {
      border-top: 0;
      border-right: 0;
  }

  .sigma_responsive-table tr.total td:last-child {
      border-left: 0;
      border-top: 0;
  }

  .sigma_responsive-table tr.total td:nth-child(2) {
      display: none;
  }

  .sigma_responsive-table tr.total td {
      width: auto;
      text-align: left;
  }

  .sigma_responsive-table tr.total {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
  }

  .sigma_responsive-table td::before {
      content: attr(data-title) ": ";
      font-weight: 700;
      float: left;
  }

  .sigma_responsive-table td.remove::before {
      display: none;
  }

  .sigma_responsive-table tr {
      border-bottom: 1px solid #F0F2F3;
  }

  .sigma_responsive-table .cart-product-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      padding: 0;
  }

  .sigma_responsive-table .cart-product-wrapper img {
      margin-right: 0;
      margin-left: 10px;
  }

  .sigma_quick-view-modal .sigma_product-single-content {
      padding: 0;
  }

  .sigma_quick-view-modal img {
      margin-bottom: 30px;
  }

  .footer-widget {
      text-align: center;
  }
  .sigma_footer-bottom ul li a{
    padding-right: 0;
  }

  .sigma_footer .social-media {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
  }

  .sigma_footer-buttons a {
      margin-bottom: 5px;
  }

  .footer-widget ul li a {
      -webkit-transform: translate(0);
      -ms-transform: translate(0);
      transform: translate(0);
  }

  .footer-widget ul li a::before {
    display: none;
  }

  .sigma_footer-top {
    display: block;
    text-align: center;
  }

  .sigma_footer-logo {
    margin: 0 auto 20px;
  }
  .sigma_footer.footer-2 .sigma_footer-top{
    padding: 35px 0;
  }
  .sigma_footer.footer-2 .sigma_footer-logo{
    margin: 0;
  }

  .sigma_footer-bottom ul {
    display: block;
    text-align: center;
  }

  .sigma_footer-bottom ul li+li {
    margin-top: 10px;
  }

  .sigma_footer-bottom .sigma_footer-copyright {
    display: block;
    text-align: center;
  }

  .sigma_footer-bottom .sigma_footer-copyright>a {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
  }

  .sigma_post.sigma_post-list {
    display: block;
    padding: 0;
  }
  .sigma_post.sigma_post-list.sigma_sermon{
    display: flex;
    padding: 20px;
  }
  .sigma_sermon-icons{
    margin: 15px 0 0;
  }
  .sigma_post.sigma_post-list.sigma_sermon .sigma_post-body h5{
    font-size: 17px;
  }

  .sigma_post.sigma_post-list .sigma_post-body {
    padding: 20px;
  }

  .sigma_subheader-inner {
    display: block;
  }

  .sigma_subheader .breadcrumb {
    margin-top: 20px;
  }

  .sigma_header-top-inner {
    display: block;
  }
  .sigma_header-top-links{
    justify-content: center;
    padding-bottom: 15px;
  }
  .sigma_header.header-3 .sigma_header-top-links{
    padding-bottom: 0;
  }
  .sigma_header.header-3 .sigma_header-top-links + .sigma_header-top-links{
    padding-top: 15px;
  }
  .sigma_header-top-links a{
    padding: 0;
  }
  .sigma_header-top-inner{
    padding: 15px 0;
  }
  .sigma_header-top-links > li + li > a{
    padding-left: 10px;
    margin-left: 10px;
    border-left: 0;
  }
  .sigma_header-top-cta{
    text-align: center;
  }
  .sigma_header-top-cta a{
    display: inline-block;
  }

  .sigma_event{
    display: block;
    margin: 0 auto 30px;
  }
  .sigma_event-body{
    padding-top: 20px;
  }
  .sigma_event-thumb,
  .sigma_event-thumb img{
    width: 100%;
    margin-right: 0;
  }
  .sigma_event .sigma_event-date{
    width: 100%;
    text-align: left;
    padding: 5px 13px;
    font-size: 13px;
    width: auto;
    height: auto;
    display: inline-block;
    margin: 0 auto;
    width: auto;
    color: #ff9044;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .sigma_event .sigma_event-date span{
    display: inline-block;
    font-size: 13px;
    margin-right: 3px;
  }
  .sigma_timeline-image i{
    display: none;
  }
  .sigma_timeline-nodes::before{
    display: none;
  }
  .sigma_timeline-nodes{
    margin-bottom: 30px;
  }
  .sigma_timeline-nodes:nth-child(odd) .sigma_timeline-date{
    text-align: right;
  }
  .sigma_timeline-nodes:nth-child(even) .sigma_timeline-date{
    text-align: left;
  }

}

@media (max-width: 575px) {

  .sigma_btn-custom{
    padding: 8px 18px;
    font-size: 13px;
  }

  .sigma_header-top-links > li + li > a{
    padding-left: 5px;
    margin-left: 5px;
  }
  .sigma_header-controls ul>li{
    margin-left: 15px;
  }
  .navbar-brand{
    width: 120px;
  }
  .sigma_header-top-links a{
    font-size: 12px;
  }
  .sigma_banner h1{
    font-size: 40px;
  }

  .sigma_header.header-2 .sigma_header-middle .navbar {
      padding: 10px 0;
  }

  .section-title.flex-title .nav {
      width: 100%;
      margin: 20px 0 0;
  }

  .modal-body {
      padding: 20px;
  }

  .sigma_product-atc-form .qty-outter,
  .sigma_product-atc-form .qty-outter .sigma_btn-custom {
      display: block;
  }

  .sigma_product-atc-form .qty-outter .sigma_btn-custom+.qty {
      margin-left: 0;
      margin-top: 20px;
  }

  .qty input {
      width: 100%;
  }

  .sigma_product.sigma_product-list {
      display: block;
  }

  .sigma_product.sigma_product-list .sigma_product-thumb {
      width: auto;
      margin-right: 0;
  }

  .sigma_cta-notice {
      padding: 20px;
  }

  .sigma_product.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons a {
      flex: 0 100%;
      width: 100%;
  }

  .sigma_product.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons a:first-child {
      border-radius: 0;
  }

  .sigma_product.sigma_product-has-buttons .sigma_product-footer .sigma_product-buttons a+a {
      border-radius: 0 0 8px 8px;
  }

  .sigma_header-cart-content,
  .sigma_header-controls-inner li.sigma_header-favorites {
      display: none;
  }

  .sigma_header-controls-inner li.sigma_header-cart a,
  .sigma_header-controls-inner li a,
  .sigma_header-controls .aside-toggler {
      padding: 0;
      width: 40px;
      height: 40px;
  }

  .sigma_header-controls-inner li.sigma_header-cart a i {
      font-size: 21px;
  }

  .sigma_header-middle .navbar {
      padding: 10px 0;
  }

  .sigma_shop-global {
      display: block;
  }

  .sigma_shop-global p {
      margin-bottom: 20px;
  }

  .sigma_product-additional-info .tab-content {
      padding: 20px;
  }

  .comments-list .comment-item span {
      position: relative;
      top: auto;
      right: auto;
      display: block;
  }

  .comments-list .comment-item img {
      width: 45px;
      margin-right: 15px;
  }

  .sigma_post-single-meta {
      display: block;
  }

  .sigma_post-single-meta-item+.sigma_post-single-meta-item {
      margin-top: 20px;
  }

  .sigma_post.sigma_post-list.sigma_sermon{
    display: block;
  }
  .sigma_post.sigma_post-list.sigma_sermon .sigma_post-thumb{
    margin-right: 0;
    margin-bottom: 15px;
  }
  .sigma_post.sigma_post-list.sigma_sermon .sigma_post-thumb img{
    width: auto;
  }

  .sigma_auth-form {
      padding: 30px;
  }

  .sigma_cta-content p {
      margin-bottom: 10px;
      max-width: 100%;
  }

  .sigma_header-top-sm li a {
      font-size: 13px;
  }
  .sigma_header-top-sm li a i {
      display: none;
  }
  .sigma_image-wrap .sigma_image-box .sigma_video-box .sigma_video_wrapper,
  .sigma_image-wrap .sigma_image-box .sigma_video-box{
      width: 270px;
  }
  .sigma_testimonial .slick-dots{
    justify-content: flex-start;
    margin: 20px 0 0;
  }
  .sigma_search-form-wrapper form {
    width: 100%;
  }
  .sigma_search-form-wrapper form input {
    font-size: 30px;
    padding: 10px 0;
  }
  .sigma_search-form-wrapper .sigma_search-btn {
    font-size: 25px;
  }
  .sigma_search-form-wrapper .close-btn{
    top: 20px;
    right: 20px;
  }

}
