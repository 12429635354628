/* You can add global styles to this file, and also import other style files */
@import '~magnific-popup/dist/magnific-popup';
button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.cart-open + .sigma_aside-overlay.sigma_cart-sidebar-overlay {
    opacity: 1;
    visibility: visible;
}
.cart-open.sigma_cart-sidebar-wrapper.sigma_aside {
    right: 0;
}
.mobile-open.mobile-menu {
    display: block;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header::after,
.sigma_sermon-box .sigma_sermon-wrap .card .card-header::before{
    display: none;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link{
    width: 100%;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link.collapsed::before{
    opacity: 1;
    transform: rotate(90deg);
    left: auto;
}
.banner-bottom>img{
    height: 360px;
    object-fit: cover;
}
.gallery a img{
    height: 250px;
    object-fit: cover;
    width: 100%;
    object-position: center;
}
.sigma_product-additional-info .comment-body .sigma_rating span{
    margin-bottom: 0;
    position: relative;
}
.sigma_round-button.active {
    color: #ffffff;
    background: #ffa80a;
    border-radius: 50%;
}
.sigma_round-button.active .sigma_round-button-circle {
    stroke-dashoffset: 700;
    stroke-dasharray: 700;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin-top: 20px;
	justify-content: center;
	margin-bottom: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next, pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span, pagination-template .ngx-pagination li.pagination-previous span, pagination-template .ngx-pagination li a {
	border-radius: 0px;
	color: #1f1f1f;
	border: 1px solid #eee;
	background-color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 15px 20px!important;
	margin: 0;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.25;
    box-shadow: 0 3px 24px rgb(0 0 0 / 4%);
}
pagination-template .ngx-pagination li.current {
	border-radius: 0px;
	color: #fff;
	border: 1px solid #eee;
	background-color: #ff9044; 
	font-size: 14px;
	font-weight: 600;
	padding: 15px 20px!important;
	margin: 0;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.25;
    box-shadow: 0 3px 24px rgb(0 0 0 / 4%);
}
pagination-template .ngx-pagination li.pagination-next::after, pagination-template .ngx-pagination li.pagination-next a:after, pagination-template .ngx-pagination li.pagination-previous a::before, pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
pagination-template .ngx-pagination li.pagination-previous a,
pagination-template .ngx-pagination li.pagination-previous span{
    margin: 0;
}
@media (max-width: 600px){
    pagination-template .ngx-pagination{
        justify-content: center;
        margin-top: 20px;
    }
    .ngx-pagination.responsive .small-screen{
        display: none!important;
    }
    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: block!important;
    }
}
/* Range slider */
.ngx-slider .ngx-slider-bubble{
    padding: 1px 5px!important;
    color: #ffffff!important;
    font-size: 10px!important;
    background-color: #1f1f1f;
    border-radius: 4px;
}
.ngx-slider .ngx-slider-bubble::after{
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #1f1f1f;
}
.ngx-slider .ngx-slider-bar{
    background: #e1e4e9!important;
    height: 12px!important;
    border-radius: 4px!important;
}
.ngx-slider .ngx-slider-selection{
    background: #1f1f1f!important;
    border-radius: 0px!important;
}
.ngx-slider .ngx-slider-pointer:after {
    display: none!important;
}
.ngx-slider .ngx-slider-pointer{
    width: 2px!important;
    height: 18px!important;
    top: -3px!important;
    border: 0px solid #1f1f1f;
    background-color: #1f1f1f!important;
}